import { useEffect, useState } from 'react';
import 'yet-another-react-lightbox/styles.css';

import { useAgent } from '../../context/AgentContext';
import { useUser } from '../../context/UserContext';

import { useNavigate } from 'react-router-dom';
import IconCross from '../icons/IconCross';
import { deleteMessage, extractAndRemoveTemplateString } from '../utils/messageUtils';
import { agentPhotoSubmit, extractPhotoTags } from '../utils/photoUtils';
import PhotoProcessing from './PhotoProcessing';
import { systemMessageTemplate } from '../../constants/constants';
import ImageWithLoader from './ImageWithLoader';

const Avatar = ({ displayName, avatarURL }) => {
	return (
		<div className="text-gray-400 text-xs min-w-fit">
			<img className="avatar" alt="" src={avatarURL} />
			{displayName}
		</div>
	);
};

const ChatBubble = ({ text, isAgent }) => {
	const parts = text ? text.split(/(\(.*?\)|\*.*?\*)/g) : '';

	const formattedText = parts.map((part, index) => {
		if ((part.startsWith('(') && part.endsWith(')')) || (part.startsWith('*') && part.endsWith('*'))) {
			return (
				<span key={index} className={`${isAgent ? 'text-violet-700' : 'text-blue-100'} italic`}>
					*{part.slice(1, -1).replace(/emoji/g, '')}*
				</span>
			);
		}
		return part;
	});

	return <p className="chatbubble m-2 text-xs sm:text-base">{formattedText}</p>;
};

const UserMessage = ({ message }) => {
	const { displayName, avatarURL } = useUser();
	const [imgData, setImgData] = useState(undefined);
	const { userAgent } = useAgent();
	const [dimensions, setDimensions] = useState(0);
	const navigate = useNavigate();

	useEffect(() => {
		if (message.imgData) {
			setImgData(message.imgData);
			const originalWidth = message.imgData.width;
			const originalHeight = message.imgData.height;
			const aspectRatio = originalWidth / originalHeight;
			const maxDimension = 224;

			let width, height;

			if (originalWidth > originalHeight) {
				width = maxDimension;
				height = maxDimension / aspectRatio;
			} else {
				width = maxDimension * aspectRatio;
				height = maxDimension;
			}
			setDimensions({ width, height });
		}
	}, [message]);

	return (
		<div>
			<div className={`flex align-middle flex-row-reverse`}>
				{imgData && (
					<div className="mr-14 mt-4 mb-1 relative" style={{ width: dimensions.width, height: dimensions.height }}>
						<ImageWithLoader
							onClick={() => navigate(`/room/${userAgent.agentId}/imagefull/${message.id}`)}
							className="absolute bottom-0 right-0 w-full h-full object-contain rounded shadow cursor-pointer"
							alt=""
							src={imgData.imgThumbUrl}
						/>
					</div>
				)}
			</div>
			<div className={`message sent`}>
				<Avatar displayName={displayName} avatarURL={avatarURL} />
				<ChatBubble text={message.text} />
			</div>
		</div>
	);
};

const SystemMessage = ({ message }) => {
	const { userAgent } = useAgent();
	const { uid } = useUser();
	const [tags, setTags] = useState('');
	const [clicked, setClicked] = useState(false);

	useEffect(() => {
		if (message.tags) {
			setTags(message.tags);
		}
	}, [message]);

	const handleAgentPhotoSubmit = async () => {
		setClicked(true);
		await agentPhotoSubmit(userAgent.agentId, message.id, tags);
	};

	const { extractedString, cleanedText } = extractAndRemoveTemplateString(message.text);

	const renderExtraSystemComponent = (string) => {
		if (string === systemMessageTemplate.sendPhotoMessage) {
			return <PhotoProcessing />;
		}
	};

	return (
		<div>
			<div className="bg-black text-white bg-opacity-10 p-2 rounded-lg">
				<button onClick={() => deleteMessage(uid, userAgent.agentId, message.id)} className="text-gray-500 hover:text-gray-400 -m-1">
					<IconCross className={'w-3 h-3'} />
				</button>
				<div className="text-xs sm:text-base italic text-center">
					<p className="opacity-25">{cleanedText}</p>
					{extractedString && renderExtraSystemComponent(extractedString)}
					{tags && !clicked && (
						<button onClick={handleAgentPhotoSubmit} className="bg-green-500 rounded px-4 mt-2 hover:bg-green-700 opacity-80">
							Send It!
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

const AgentMessage = ({ message }) => {
	const { userAgent } = useAgent();
	const [imgData, setImgData] = useState(undefined);
	const [dimensions, setDimensions] = useState(0);
	const navigate = useNavigate();

	const avatarURL = userAgent.avatarURL ?? '';
	const displayName = userAgent.name ?? '';

	useEffect(() => {
		if (message.imgData) {
			setImgData(message.imgData);
			const originalWidth = message.imgData.width;
			const originalHeight = message.imgData.height;
			const aspectRatio = originalWidth / originalHeight;
			const maxDimension = 224;

			let width, height;

			if (originalWidth > originalHeight) {
				width = maxDimension;
				height = maxDimension / aspectRatio;
			} else {
				width = maxDimension * aspectRatio;
				height = maxDimension;
			}
			setDimensions({ width, height });
		}
	}, [message]);

	const handleImageClick = (imgUrl) => {
		navigate(`/room/${userAgent.agentId}/imagefull/${message.id}`);

		// marketing
		// window.location.href = imgUrl;
	};

	return (
		<div>
			{imgData && (
				<div className="ml-14 mt-4 mb-1 relative" style={{ width: dimensions.width, height: dimensions.height }}>
					<ImageWithLoader
						onClick={() => handleImageClick(imgData.imgThumbUrl)}
						className="absolute bottom-0 left-0 w-full h-full object-contain rounded shadow cursor-pointer"
						alt=""
						src={imgData.imgThumbUrl}
					/>
				</div>
			)}
			<div className={`message received`}>
				<Avatar displayName={displayName} avatarURL={avatarURL} />
				<ChatBubble text={message.text} isAgent={true} />
			</div>
		</div>
	);
};

const Message = ({ message }) => {
	const { userAgent } = useAgent();
	const { displayName } = useUser();

	if (!message.text) {
		return;
	}

	const isAgentMsg = message.tokens;
	const isSystemMsg = message.system;

	if (!isSystemMsg) {
		const { hasTags, cleanText } = extractPhotoTags(message.text, userAgent.name, displayName);
		if (hasTags) {
			message.text = cleanText;
		}
	}

	return isAgentMsg ? <AgentMessage message={message} /> : isSystemMsg ? <SystemMessage message={message} /> : <UserMessage message={message} />;
};

export default Message;
