import { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const AgentFieldAdder = () => {
	const [agents, setAgents] = useState([]);
	const [selectedAgent, setSelectedAgent] = useState(null);
	const [fieldName, setFieldName] = useState('');
	const [fieldValue, setFieldValue] = useState('');
	const [selectedCollection, setSelectedCollection] = useState('agents');

	const resetForm = () => {
		setFieldName('');
		setFieldValue('');
	};

	const fetchAgents = useCallback(async () => {
		const agentsData = await getDocs(collection(db, selectedCollection));
		setAgents(agentsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
	}, [selectedCollection]);

	useEffect(() => {
		fetchAgents();
	}, [fetchAgents, selectedCollection]);

	const handleAgentChange = (e) => {
		const agentId = e.target.value;
		const agent = agents.find((a) => a.id === agentId);
		setSelectedAgent(agent);
	};

	const handleCollectionChange = (e) => {
		setSelectedCollection(e.target.value);
	};

	const handleFieldNameChange = (e) => {
		setFieldName(e.target.value);
	};

	const handleFieldValueChange = (e) => {
		setFieldValue(e.target.value);
	};

	const handleSubmit = async (e, fieldType) => {
		e.preventDefault();

		if (selectedAgent && fieldName) {
			let fieldValueToSave = fieldValue;
			if (fieldType === 'array') {
				fieldValueToSave = fieldValue.split(',').map((item) => item.trim());
			} else if (fieldType === 'object') {
				try {
					fieldValueToSave = JSON.parse(fieldValue);
				} catch (error) {
					console.error('Invalid JSON format', error);
					alert('Invalid JSON format');
					return;
				}
			} else if (fieldType === 'boolean') {
				fieldValueToSave = fieldValue === 'true';
			}

			const agentRef = doc(db, selectedCollection, selectedAgent.id);
			await updateDoc(agentRef, { [fieldName]: fieldValueToSave });
			resetForm();
		}
	};

	return (
		<div className="mx-auto bg-gray-400 absolute top-12 left-0 text-left p-8 w-screen">
			<h1 className="text-xl font-bold mb-4">Agent Field Adder</h1>
			<div className="mb-4">
				<label className="block text-gray-700 text-sm font-bold mb-2">Select Collection:</label>
				<select className="" value={selectedCollection} onChange={(e) => handleCollectionChange(e)}>
					<option value="agents">Agents</option>
					<option value="imageGen">ImageGen</option>
				</select>
			</div>
			<div className="mb-4">
				<label className="block text-gray-700 text-sm font-bold mb-2">Select Agent:</label>
				<select className="" value={selectedAgent?.id || ''} onChange={handleAgentChange}>
					<option value="">Select an agent</option>
					{agents.map((agent) => (
						<option key={agent.id} value={agent.id}>
							{agent.name}
						</option>
					))}
				</select>
			</div>
			{selectedAgent && (
				<div>
					<form onSubmit={(e) => handleSubmit(e, 'string')}>
						<h2 className="text-lg font-bold mb-2">Add String Field</h2>
						<input className="w-full mb-2" type="text" placeholder="Field name" value={fieldName} onChange={handleFieldNameChange} />
						<input className="w-full mb-2" type="text" placeholder="Field value" value={fieldValue} onChange={handleFieldValueChange} />
						<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
							Add String
						</button>
					</form>
					<form onSubmit={(e) => handleSubmit(e, 'number')} className="mt-4">
						<h2 className="text-lg font-bold mb-2">Add Number Field</h2>
						<input className="w-full mb-2" type="text" placeholder="Field name" value={fieldName} onChange={handleFieldNameChange} />
						<input className="w-full mb-2" type="number" placeholder="Field value" value={fieldValue} onChange={handleFieldValueChange} />
						<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
							Add Number
						</button>
					</form>
					<form onSubmit={(e) => handleSubmit(e, 'array')} className="mt-4">
						<h2 className="text-lg font-bold mb-2">Add Array Field</h2>
						<input className="w-full mb-2" type="text" placeholder="Field name" value={fieldName} onChange={handleFieldNameChange} />
						<input className="w-full mb-2" type="text" placeholder="Field value (comma-separated)" value={fieldValue} onChange={handleFieldValueChange} />
						<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
							Add Array
						</button>
					</form>
					<form onSubmit={(e) => handleSubmit(e, 'object')} className="mt-4">
						<h2 className="text-lg font-bold mb-2">Add Object Field</h2>
						<input className="w-full mb-2" type="text" placeholder="Field name" value={fieldName} onChange={handleFieldNameChange} />
						<textarea className="w-full mb-2" rows="4" placeholder="Field value (JSON formatted object)" value={fieldValue} onChange={handleFieldValueChange} />
						<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
							Add Object
						</button>
					</form>
					<form onSubmit={(e) => handleSubmit(e, 'boolean')} className="mt-4">
						<h2 className="text-lg font-bold mb-2">Add Boolean Field</h2>
						<input className="w-full mb-2" type="text" placeholder="Field name" value={fieldName} onChange={handleFieldNameChange} />
						<div className="mb-2">
							<label className="inline-flex items-center">
								<input type="radio" name="booleanValue" value="true" onChange={handleFieldValueChange} />
								<span className="ml-2">True</span>
							</label>
							<label className="inline-flex items-center ml-4">
								<input type="radio" name="booleanValue" value="false" onChange={handleFieldValueChange} />
								<span className="ml-2">False</span>
							</label>
						</div>
						<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
							Add Boolean
						</button>
					</form>
				</div>
			)}
		</div>
	);
};

export default AgentFieldAdder;
