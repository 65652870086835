import SignIn from './SignIn';
import IconCross from './icons/IconCross';

const SignInModal = ({ closePortal }) => {
	return (
		<div className="flex justify-center items-center">
			<div onClick={closePortal} className="fixed w-full h-full top-0 left-0 z-50 bg-opacity-60 bg-black"></div>
			<div style={{ maxWidth: '650px', maxHeight: '80vh' }} className="fixed top-2/4 -translate-y-2/4 z-50 m-4">
				<div className="relative bg-slate-900 rounded-lg shadow w-full h-full p-2 px-4 sm:p-4 sm:px-8 pb-8 sm:pb-16">
					<div className="flex">
						<div className="flex-grow"></div>
						<button onClick={closePortal} type="button" className="text-gray-600 hover:text-gray-400 rounded-lg text-sm">
							<IconCross className={'w-4 h-4 sm:w-6 sm:h-6'} />
						</button>
					</div>

					<h3 className="mt-8 mb-2 sm:mb-4 text-xs sm:text-xl text-gray-400">To begin your secure private chat, you need to be signed in.</h3>
					<SignIn />
				</div>
			</div>
		</div>
	);
};

export default SignInModal;
