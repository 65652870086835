import { Spin } from 'react-cssfx-loading';

const PhotoProcessing = () => {
	return (
		<div className="flex justify-center opacity-25">
			<Spin color="#FFFFFF" />
		</div>
	);
};

export default PhotoProcessing;
