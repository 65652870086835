import { useEffect, useRef } from 'react';
import { query, collection, orderBy, onSnapshot, limit } from 'firebase/firestore';
import { db } from '../firebase';
import { useAgent } from '../context/AgentContext';

import Message from '../components/messages/Message';
import SendMessage from '../components/SendMessage';
import NewRoomMsg from '../components/NewRoomMessage';
import TypingIndicator from '../components/messages/TypingIndicator';
import { useMessages } from '../hooks/useMessages';
import { useUser } from '../context/UserContext';
import AgentsHeader from '../components/AgentHeader';

const ChatRoom = () => {
	const { userAgent } = useAgent();
	const { uid } = useUser();
	const { messages, isNewRoom, isTyping, refetch, queryMsgLimit } = useMessages(userAgent);

	const scrollWindow = useRef();

	useEffect(() => {
		if (userAgent && uid) {
			const messagesRef = collection(db, `users/${uid}/agents/${userAgent.agentId}/messages`);
			const q = query(messagesRef, limit(queryMsgLimit), orderBy('timestamp', 'desc'));
			const unsubscribe = onSnapshot(q, () => {
				refetch();
			});

			return () => unsubscribe();
		}
	}, [refetch, userAgent, uid, queryMsgLimit]);

	useEffect(() => {
		if (scrollWindow) {
			scrollWindow.current.addEventListener('DOMNodeInserted', (event) => {
				const { currentTarget: target } = event;
				target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
			});
		}
	}, []);

	return (
		<>
			{isNewRoom && <NewRoomMsg />}

			<main className="pt-16 sm:pt-24 pb-16 sm:pb-20 px-2 h-screen flex flex-col overflow-y-scroll" ref={scrollWindow}>
				{messages && messages.map((message) => <Message key={message.id} message={message} />)}
			</main>
			{isTyping && <TypingIndicator agent={userAgent} />}
			<AgentsHeader />
			<SendMessage />
		</>
	);
};

export default ChatRoom;
