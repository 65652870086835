import { findLatestAgentMessage, useMessages } from '../hooks/useMessages';
import { useAgent } from '../context/AgentContext';
import AgentOptionsModal from './agentOptions/AgentOptionsModal';
import { useUser } from '../context/UserContext';
import { PortalWithState } from 'react-portal';

const AgentHeader = () => {
	const { userAgent } = useAgent();
	const { messages } = useMessages(userAgent);

	const { userData } = useUser();
	const { currentEmotionalState } = findLatestAgentMessage(messages);

	return (
		<div style={{ maxWidth: '728px' }} className="absolute agent_header top-10 sm:top-12 w-full h-6 sm:h-10 text-left px-4 py-2 text-xs sm:text-base">
			{userData && userAgent && (
				<PortalWithState closeOnEsc>
					{({ openPortal, closePortal, isOpen, portal }) => (
						<>
							<div onClick={openPortal} className="flex space-x-3 -translate-y-1 sm:-translate-y-0 items-center text-white cursor-pointer">
								<div>{userAgent.name}</div>
								<p className="text-xs italic opacity-50">- {currentEmotionalState}</p>
								<div className="flex-grow"></div>
								<div>xp: {userAgent.xp}</div>
							</div>

							{portal(<AgentOptionsModal closePortal={closePortal} />)}
						</>
					)}
				</PortalWithState>
			)}
		</div>
	);
};

export default AgentHeader;
