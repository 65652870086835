import { useState } from 'react';
import { Spin } from 'react-cssfx-loading';
import { useAgent } from '../../../context/AgentContext';
import { callChangeContextLength } from '../../../context/agentUtils';
import IconCircleTick from '../../icons/IconCircleTick';

const ContextLengthButton = ({ userAgent, text, newContextLength }) => {
	const [isSaving, setIsSaving] = useState(false);
	const isCurrent = newContextLength === userAgent.contextLength;

	const handleContextLength = async (agentId, newContextLength) => {
		setIsSaving(true);
		await callChangeContextLength(agentId, newContextLength);
		setIsSaving(false);
	};

	return (
		<button
			disabled={isCurrent || isSaving}
			onClick={() => handleContextLength(userAgent.agentId, newContextLength)}
			className={`flex justify-center items-center mx-1 w-32 shadow rounded px-1 py-1 text-xs font-medium text-center text-white border ${
				isCurrent ? 'cursor-default border-green-500' : 'bg-blue-600 hover:bg-blue-800  border-blue-500'
			} `}
		>
			{isSaving && <Spin style={{ marginRight: '4px' }} color="#ffffff" width="12px" height="12px" duration="3s" />}
			{!isSaving && isCurrent && <IconCircleTick className={'w-3 h-3 mr-1 text-green-300'} />}

			{text}
		</button>
	);
};

const ContextLengthButtons = () => {
	const { userAgent } = useAgent();

	return (
		<div className="mb-8">
			<p>Message Context Length: </p>
			<div className="flex items-center">
				<ContextLengthButton text="Short (2)" newContextLength={2} userAgent={userAgent} />
				<ContextLengthButton text="Normal (10)" newContextLength={10} userAgent={userAgent} />
				<ContextLengthButton text="Long (25)" newContextLength={25} userAgent={userAgent} />
			</div>
		</div>
	);
};

export default ContextLengthButtons;
