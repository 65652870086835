import { Messaging } from 'react-cssfx-loading';

const TypingIndicator = ({ agent }) => {
	return (
		<div className="fixed bottom-11 sm:bottom-16 px-2 py-1 flex opacity-50">
			<Messaging color="white" width="5px" height="5px" duration="0.6s" />
			<span className="px-2 text-xs text-white">{agent.name} is typing...</span>
		</div>
	);
};

export default TypingIndicator;
