import { useAgent } from '../context/AgentContext';
import { useUser } from '../context/UserContext';
import IconArrowDown from './icons/IconArrowDown';

const NewRoomMsg = () => {
	const { userAgent } = useAgent();
	const { displayName } = useUser();

	return (
		<>
			<div className="absolute h-96 left-1/2 w-0 flex align-middle justify-center">
				<div className="fixed w-96 mx-auto text-slate-500 p-4">
					<p className="text-lg sm:text-3xl py-20">{`Hi ${displayName} welcome to ${userAgent && userAgent.name}'s private room`}</p>
					<div className="my-8">
						<p className="px-4 text-xs sm:text-base">
							Talk about anything and everything. Request photos by clicking the photo icon on the bottom left. Photos take at least 10s to generate. Be patient
						</p>
					</div>
				</div>
			</div>
			<div className="fixed bottom-14 sm:bottom-20 mx-4 text-xs sm:text-base text-slate-500">
				Request photo
				<IconArrowDown />
			</div>
			<div className="fixed bottom-14 sm:bottom-20 left-1/2 mx-4 text-xs sm:text-base text-slate-500">
				Say Hi or anything
				<IconArrowDown />
			</div>
		</>
	);
};

export default NewRoomMsg;
