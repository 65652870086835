import { useAgent } from '../context/AgentContext';
import { useUser } from '../context/UserContext';

const EmptyChatLog = () => {
	const { userAgent } = useAgent();
	const { displayName } = useUser();

	return (
		<div className="absolute h-96 left-1/2 w-0 flex align-middle justify-center">
			<div className="fixed w-96 mx-auto text-slate-500 p-4">
				<p className="text-lg sm:text-2xl py-8 mt-24">{`${userAgent && userAgent.name}'s and ${displayName}'s chat log`}</p>

				<p className="px-4 text-xs sm:text-base">Nothing to see here</p>
			</div>
		</div>
	);
};

export default EmptyChatLog;
