import { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useUser } from '../context/UserContext';
import { completeSignIn } from '../components/auth';
import { useNavigate } from 'react-router-dom';
import { isSignInWithEmailLink } from 'firebase/auth';
import IconTwitter from '../components/icons/IconTwitter';

export default function Landing() {
	const { user, updateUser } = useUser();
	const [didSignIn, setDidSignIn] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const handleSignIn = async () => {
			const urlParams = new URLSearchParams(window.location.search);
			const email = urlParams.get('email');
			const emailLink = window.location.href;

			if (isSignInWithEmailLink(auth, emailLink) && email) {
				const result = await completeSignIn(email, emailLink);
				if (result) {
					updateUser(result.user); // Update the user in the context
					setDidSignIn(true);
					window.history.replaceState({}, document.title, '/');
				}
			}
		};

		handleSignIn();
	}, [updateUser]);

	useEffect(() => {
		if (didSignIn && user) {
			navigate('/lobby');
		}
	}, [didSignIn, user, navigate]);

	return (
		<div className="background-image bg-cover">
			{/* MAIN */}
			<div className="mx-auto items-center">
				<div className="absolute left-1/2 -translate-x-2/4 bottom-10 sm:bottom-32 w-5/6 sm:w-4/6 md:w-4/6 lg:w-2/4 xl:w-3/4 justify-center overflow-y-hidden mx-auto">
					<h1 className="pb-4 text-xl md:text-2xl text-white leading-tight md:text-left">Fall in love with your own personalized AI companions</h1>
					<div className="text-white p-2 text-sm sm:text-base sm:p-4 max-w-xl">
						<h1 className="text-lg">Welcome to the Beta</h1>
						<ul className="list-none">
							<li>- Prototype UI but functional chat</li>
							<li>- Select an AI companion for one-on-one chat</li>
							<li>- Beta data, removed after 24h</li>
							<li>- Expect major updates</li>
							<li>- Enjoy! (Unlock limitless roleplay 😂)</li>
							<li className="flex items-center">
								Report bugs/issues/suggestions, DM me:
								<a
									className="inline-block text-blue-300 no-underline hover:text-pink-500 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4"
									href="https://twitter.com/synthloveio"
									target="_blank"
									rel="noreferrer noopener"
								>
									<IconTwitter className={`fill-current h-6`} />
								</a>
							</li>
						</ul>

						<button onClick={() => navigate('/lobby')} className="flex justify-center bg-blue-600 hover:bg-blue-500 p-2 rounded opacity-80 w-full items-center">
							<p className="px-2">Enter Lobby</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
