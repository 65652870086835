import { db } from '../../firebase';
import { getDocs, orderBy, query, writeBatch, limit, collection, deleteDoc, doc } from 'firebase/firestore';

export const deleteMessage = async (userId, agentId, messageId) => {
	try {
		const messageRef = doc(db, `users/${userId}/agents/${agentId}/messages/${messageId}`);
		await deleteDoc(messageRef);
	} catch (error) {
		console.log(error);
	}
};

async function deleteCollection(db, collectionPath, batchSize) {
	const collectionRef = collection(db, collectionPath);
	const q = query(collectionRef, limit(batchSize));

	const snapshot = await getDocs(q);
	if (snapshot.size === 0) {
		return;
	}

	const batch = writeBatch(db);
	snapshot.docs.forEach((doc) => {
		batch.delete(doc.ref);
	});

	await batch.commit();

	// Use setTimeout with a delay of 0 to avoid exceeding the call stack limit.
	setTimeout(() => {
		deleteCollection(db, collectionPath, batchSize);
	}, 0);
}

export const exportChatLog = async (userId, agentId, displayName, agentName) => {
	const messages = [];
	const messagesRef = collection(db, `users/${userId}/agents/${agentId}/messages`);
	const q = query(messagesRef, orderBy('timestamp', 'asc'));
	const querySnapshot = await getDocs(q);
	querySnapshot.forEach((doc) => {
		const data = doc.data();
		if (data.system) return;
		messages.push({ ...data, id: doc.id });
	});

	const chatLog = messages
		.map((msg) => {
			try {
				const time = new Date(msg.timestamp.toMillis()).toLocaleString();
				msg.name = msg.tokens ? agentName : displayName;
				return `[${time}] ${msg.system ? '[SYSTEM]' : msg.name}: ${msg.text}`;
			} catch (error) {
				console.log(error);
			}
			return '';
		})
		.join('\n');

	const chatLogWindow = window.open('', '_blank');
	chatLogWindow.document.write('<pre>' + chatLog + '</pre>');
	chatLogWindow.document.close();
};

export const clearChat = async (userId, agentId) => {
	try {
		const collectionPath = `users/${userId}/agents/${agentId}/messages`;
		const batchSize = 500;
		deleteCollection(db, collectionPath, batchSize);
	} catch (error) {
		console.log(error);
	}
};

export const removeLastInteraction = async (userId, agentId) => {
	try {
		const q = query(collection(db, `users/${userId}/agents/${agentId}/messages`), limit(10), orderBy('timestamp', 'desc'));
		const querySnapshot = await getDocs(q);
		const querySnapshotDocs = querySnapshot.docs;
		const batch = writeBatch(db);

		for (let i = 0; i < querySnapshotDocs.length; i++) {
			const doc = querySnapshotDocs[i];
			let data = doc.data();

			if (data.tokens) {
				batch.delete(doc.ref);
			}

			if (data.system) {
				batch.delete(doc.ref);
			}

			if (!data.tokens && !data.system) {
				batch.delete(doc.ref);
				break;
			}
		}

		await batch.commit();
	} catch (error) {
		console.log(error);
	}
};

export function extractAndRemoveTemplateString(text) {
	const templateRegex = /{{([^}]+)}}/;

	// Extract the template string
	const match = text.match(templateRegex);
	const extractedString = match ? match[1] : '';

	// Remove the template string from the original text
	const cleanedText = text.replace(templateRegex, '').trim();

	return { extractedString, cleanedText };
}
