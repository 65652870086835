import { useEffect, useState } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

import { db } from '../firebase';
import { useAgent } from '../context/AgentContext';
import { useUser } from '../context/UserContext';
import { detectPhotoRequest } from './utils/photoUtils';
import { findCost } from '../constants/constants';
import IconPlay from './icons/IconPlay';
import IconCog from './icons/IconCog';
import { PortalWithState } from 'react-portal';
import ChatOptionsModal from './chatOptions/ChatOptionsModal';

const SendMessage = () => {
	const { userAgent } = useAgent();
	const rateLimit = 5;
	const [input, setInput] = useState('');
	const [lastSent, setLastSent] = useState(null);
	const [canSend, setCanSend] = useState(true);
	const { user } = useUser();

	useEffect(() => {
		if (lastSent) {
			const timeDifference = (Date.now() - lastSent) / 1000;

			if (timeDifference >= rateLimit) {
				setCanSend(true);
			} else {
				setCanSend(false);
				const timer = setTimeout(() => {
					setCanSend(true);
				}, (rateLimit - timeDifference) * 1000);
				return () => clearTimeout(timer);
			}
		}
	}, [lastSent]);

	const sendMessage = async (e) => {
		e.preventDefault();
		if (input.trim() === '' || !canSend) return;
		if (input.length > 164) return;

		const { match, option } = detectPhotoRequest(input, userAgent.name);

		const doc = { text: input, timestamp: serverTimestamp() };

		if (match && userAgent.availablePoints > findCost(option)) {
			doc.photoRequest = true;
			doc.photoOption = option;
		} else if (match && userAgent.availablePoints < findCost(option)) {
			doc.text = `Sorry, you don't have enough AP to receive a photo. Keep chatting to earn more Attraction Points!`;
			doc.system = true;
		}

		try {
			await addDoc(collection(db, `users/${user.uid}/agents/${userAgent.agentId}/messages`), doc);
		} catch (error) {
			console.log(error);
		}

		setInput('');
		setLastSent(Date.now());
	};

	return (
		<form style={{ maxWidth: '728px' }} className="text-xs sm:text-lg fixed bottom-0 w-full flex" onSubmit={sendMessage}>
			<PortalWithState closeOnEsc>
				{({ openPortal, closePortal, isOpen, portal }) => (
					<>
						<div onClick={openPortal} className={`w-16 sm:w-20 flex align-middle justify-center p-4 text-blue-300 bg-blue-800 cursor-pointer`}>
							<IconCog className={`w-4 h-4 sm:w-8 sm:h-8`} />
						</div>
						{portal(<ChatOptionsModal closePortal={closePortal} />)}
					</>
				)}
			</PortalWithState>
			<input
				className="w-full text-white outline-none border-none px-3 bg-slate-700 focus:ring-0"
				maxLength={164}
				value={input}
				onChange={(e) => setInput(e.target.value)}
				type="text"
				placeholder="type something or request a photo"
			/>
			<button
				disabled={!canSend}
				className={`w-16 sm:w-20 flex align-middle justify-center p-4 ${!canSend || input.length <= 0 ? 'cursor-not-allowed bg-blue-900 text-blue-400' : 'text-blue-300 bg-blue-800'}`}
				type="submit"
			>
				<IconPlay className="w-4 h-4 sm:w-8 sm:h-8" />
			</button>
		</form>
	);
};

export default SendMessage;
