import { useAgent } from '../../context/AgentContext';
import { useUser } from '../../context/UserContext';
import { findLatestAgentMessage, useMessages } from '../../hooks/useMessages';
import { findCost } from '../../constants/constants';
import { PortalWithState } from 'react-portal';
import { clearChat, removeLastInteraction } from '../utils/messageUtils';
import { photoRequest } from '../utils/photoUtils';
import { useNavigate } from 'react-router-dom';
import AgentOptionsModal from '../agentOptions/AgentOptionsModal';
import IconBin from '../icons/IconBin';
import IconCamera from '../icons/IconCamera';
import IconGallery from '../icons/IconGallery';
import IconCross from '../icons/IconCross';
import SendPhotoModal from './SendPhotoModal';
import IconChatLog from '../icons/IconChatLog';

// prettier-ignore
const messageOptions = [
	{
		id: 0,
		name: 'remove last interaction',
		handler: removeLastInteraction,
		icon: () => <IconBin className={'w-3 h-3 mr-1'} />,
	},
	{
		id: 2,
		name: 'clear chat history',
		handler: clearChat,
		icon: () => <IconBin className={'w-3 h-3 mr-1'} />,
	},
];

// prettier-ignore
const photoOptions = [
	{
    id: 0,
    name: 'selfie',
    handler: photoRequest,
    message: `take a selfie`,
    icon: () => <IconCamera className={'w-3 h-3 mr-1 text-gray-200'} /> },
	{
    id: 1,
    name: 'glamour',
    handler: photoRequest,
    message: `take a glamour photo`,
    icon: () => <IconCamera className={'w-3 h-3 mr-1 text-gray-200'} /> },
	{
    id: 2,
    name: 'cosplay',
    handler: photoRequest,
    message: `take a cosplay photo`,
    icon: () => <IconCamera className={'w-3 h-3 mr-1 text-gray-200'} /> },
	{
    id: 3,
    name: 'flirty',
    handler: photoRequest,
    message: `take a flirty photo`,
    icon: () => <IconCamera className={'w-3 h-3 mr-1 text-gray-200'} /> },
];

const ChatOptionsButton = ({ option, userId, agentId, closePortal, displayName, agentName }) => {
	const handleClick = () => {
		option.handler(userId, agentId, displayName, agentName);
		closePortal();
	};

	return (
		<button
			onClick={handleClick}
			className="flex sm:justify-center items-center w-48 shadow rounded px-2 sm:px-1 py-1 text-xs font-medium text-left sm:text-center text-white border
				bg-blue-600 hover:bg-blue-800  border-blue-500"
		>
			{option.icon()}
			{option.name}
		</button>
	);
};

const ChatLogButton = ({ navigate }) => {
	const handleClick = () => {
		navigate(`chatlog`);
	};

	return (
		<button
			onClick={handleClick}
			className="flex justify-center items-center w-48 shadow rounded px-2 py-1 text-xs font-medium text-left sm:text-center text-white border
        bg-blue-600 hover:bg-blue-800  border-blue-500"
		>
			<IconChatLog className={'w-3 h-3 mr-1 text-gray-200'} />
			chat log
		</button>
	);
};

const PhotoOptionsButton = ({ option, userId, userAgent, closePortal }) => {
	const cost = findCost(option.name);
	const isAvailable = cost <= userAgent.availablePoints;

	const handleClick = () => {
		option.handler(userId, userAgent.agentId, option);
		closePortal();
	};

	return (
		<button
			disabled={!isAvailable}
			onClick={handleClick}
			className={`flex justify-center relative items-center w-24 shadow rounded px-1 py-1 text-xs font-medium text-center text-white border ${
				isAvailable ? 'bg-blue-600 hover:bg-blue-800  border-blue-500' : 'bg-blue-400  border-blue-500'
			}`}
		>
			{option.icon()}
			{option.name}
			<span className="fixed translate-x-4 sm:translate-x-7 translate-y-5 text-right text-gray-400">{cost} AP</span>
		</button>
	);
};

const ChatOptionsModal = ({ closePortal }) => {
	const navigate = useNavigate();
	const { userAgent } = useAgent();
	const { uid, displayName } = useUser();
	const { messages } = useMessages(userAgent);
	const { tokenCost } = findLatestAgentMessage(messages);

	return (
		<div className="flex justify-center items-center text-white">
			<div onClick={closePortal} className="fixed w-full h-full top-0 left-0 z-50 bg-opacity-50 bg-black"></div>
			<div style={{ maxWidth: '650px', maxHeight: '90vh' }} className="niceScrollbar fixed top-2/4 -translate-y-2/4 rounded w-11/12 bg-gray-700 p-2 sm:p-4 z-50">
				<div className="flex items-center justify-between italic">
					<h1 className="text-xl text-purple-200">
						{userAgent.name}'s
						<span className="text-xs sm:text-sm italic text-white ml-1">photos and chatroom options</span>
					</h1>
					<button onClick={closePortal} className=" cursor-pointer text-gray-300 hover:text-white">
						<IconCross />
					</button>
				</div>
				<div className="mb-8 text-xs text-gray-300">
					<p>Synth Love Score: {userAgent.xp}</p>
					<p>Attraction Points to spend: {userAgent.availablePoints}</p>
					<p>Last interaction credits cost: {Math.round(tokenCost / 10)}</p>
				</div>
				<PortalWithState closeOnEsc>
					{({ openPortal, closePortal, isOpen, portal }) => (
						<>
							<div className="mb-8">
								<button
									onClick={openPortal}
									className="flex items-center w-48 shadow rounded px-2 py-1 text-xs font-medium text-center text-white border bg-blue-600 hover:bg-blue-800 border-blue-500"
								>
									Adjust Roleplay and Personality
								</button>
							</div>
							{portal(<AgentOptionsModal closePortal={closePortal} />)}
						</>
					)}
				</PortalWithState>

				<div className="mb-8">
					<button
						onClick={() => navigate(`/room/${userAgent.agentId}/gallery`)}
						className="flex items-center w-48 shadow rounded px-2 py-1 text-xs font-medium text-left sm:text-center text-white border bg-blue-600 hover:bg-blue-800 border-blue-500"
					>
						Photo Gallery
						<IconGallery className={'w-4 h-4 ml-2'} />
					</button>
				</div>

				<PortalWithState closeOnEsc>
					{({ openPortal, closePortal: childClosePortal, isOpen, portal }) => (
						<>
							<div className="mb-8">
								<button
									onClick={openPortal}
									className="flex items-center w-48 shadow rounded px-2 py-1 text-xs font-medium text-center text-white border bg-blue-600 hover:bg-blue-800 border-blue-500"
								>
									Send {userAgent.name} a Photo
								</button>
							</div>
							{portal(<SendPhotoModal closePortal={childClosePortal} closeParentPortal={closePortal} />)}
						</>
					)}
				</PortalWithState>

				<div className="mb-8">
					<p>Photo Requests: </p>
					<div className="flex items-center gap-2">
						{photoOptions && photoOptions.map((option) => <PhotoOptionsButton key={option.id} option={option} userId={uid} userAgent={userAgent} closePortal={closePortal} />)}
					</div>
				</div>

				<div className="mb-8">
					<p>Chat Room Options: </p>
					<div className="flex flex-wrap items-center gap-2">
						<ChatLogButton navigate={navigate} />
						{messageOptions &&
							messageOptions.map((option) => (
								<ChatOptionsButton key={option.id} option={option} userId={uid} agentId={userAgent.agentId} closePortal={closePortal} displayName={displayName} agentName={userAgent.name} />
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatOptionsModal;
