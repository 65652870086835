import React, { useState, useEffect } from 'react';
import { Spin } from 'react-cssfx-loading';

const ImageWithLoader = ({ src, alt, maxAttempts = 5, retryInterval = 500, ...rest }) => {
	const [loading, setLoading] = useState(true);
	const [imageSrc, setImageSrc] = useState('');
	const [attempts, setAttempts] = useState(0);

	useEffect(() => {
		const img = new Image();

		const loadImage = () => {
			img.src = src;
		};

		img.onload = () => {
			setImageSrc(src);
			setLoading(false);
		};

		img.onerror = () => {
			if (attempts < maxAttempts) {
				const retryTimeout = setTimeout(() => {
					setAttempts((prevAttempts) => prevAttempts + 1);
				}, retryInterval);
				return () => clearTimeout(retryTimeout);
			}
		};

		loadImage();
	}, [src, attempts, maxAttempts, retryInterval]);

	return (
		<>
			{loading ? (
				<div className="absolute w-full bottom-1/2 opacity-50">
					<Spin color="#FFFFFF" style={{ margin: 'auto', textAlign: 'center' }} />
				</div>
			) : (
				<img src={imageSrc} alt={alt} {...rest} />
			)}
		</>
	);
};

export default ImageWithLoader;
