import React from 'react';
import Navbar from './Navbar';
import { Outlet, useLocation } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';

const Layout = () => {
	const location = useLocation();
	const isGallery = location.pathname.includes('/gallery');
	const isLanding = location.pathname === '/';

	const getAppClassName = () => {
		if (isLanding) return 'App-landing';
		if (isGallery) return 'App App-gallery';
		return 'App';
	};

	const contextClass = {
		success: 'bg-slate-500',
		error: 'bg-red-600',
		info: 'bg-gray-600',
		warning: 'bg-orange-400',
		default: 'bg-indigo-600',
		dark: 'bg-white-600 font-gray-300',
	};

	return (
		<>
			<Navbar />
			<div className={getAppClassName()}>
				<Outlet />
			</div>
			<ToastContainer
				toastClassName={({ type }) => contextClass[type || 'default'] + ' text-xs relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'}
				position="top-center"
				autoClose={1000}
				hideProgressBar={true}
				transition={Flip}
				draggable={false}
				closeButton={false}
				theme="dark"
			/>
		</>
	);
};

export default Layout;
