import Fuse from 'fuse.js';
import { app, db } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const photoRequest = async (userId, agentId, option) => {
	try {
		await addDoc(collection(db, `users/${userId}/agents/${agentId}/messages`), {
			text: `${option.message}`,
			photoRequest: true,
			photoOption: option.id,
			timestamp: serverTimestamp(),
		});
	} catch (error) {
		console.log(error);
	}
};

export const agentPhotoSubmit = async (agentId, systemMessageId, tags) => {
	try {
		const functions = getFunctions(app);
		const agentPhotoSubmitFunction = httpsCallable(functions, 'agentPhotoSubmit');
		const response = await agentPhotoSubmitFunction({
			agentId: agentId,
			systemMessageId,
			option: 5,
			tags,
		});

		console.log('Cloud Function response:', response.data);
	} catch (error) {
		console.error('Error calling Firebase Cloud Function:', error);
	}
};

export function extractPhotoTags(inputString) {
	const pattern =
		/[\*(](?:sends? (?:back )?(?:a )?(?:photo|picture) (?:with|has) tags|photo tags|photo has tags|(?:[\p{L}\s]+ |[HS]he |I )?(?:shares|uploads|attaches|sends?)(?: another)?(?: a| the)? (?:photo|picture) (?:with|has|containing|including) tags) -([^*)]*)[\)*]/iu; // eslint-disable-line

	const pattern2 =
		/[\*(](?:takes? (?:a )?photo (?:with|has) tags|photo tags|photo has tags|(?:[\p{L}\s]+ |[HS]he |I )?(?:shares|uploads|attaches|takes?)(?: another)?(?: a| the)? photo (?:with|has|containing|including) tags) -([^*)]*)[\)*]/iu; // eslint-disable-line

	const pattern3 = /(?:^|\W)photo with tags -\s*([^)]*)\)?/iu;

	const match = inputString.match(pattern);
	const match2 = inputString.match(pattern2);
	const match3 = inputString.match(pattern3);

	if (!match && !match2 && !match3) {
		return { hasTags: false, tags: '', cleanText: inputString };
	}

	if (match) {
		const photoTags = match[1].trim();
		const cleanText = inputString.replace(match[0], '').trim();

		return { hasTags: true, tags: photoTags, cleanText: cleanText };
	}

	if (match2) {
		const photoTags = match2[1].trim();
		const cleanText = inputString.replace(match2[0], '').trim();

		return { hasTags: true, tags: photoTags, cleanText: cleanText };
	}

	if (match3) {
		const photoTags = match3[1].trim();
		const cleanText = inputString.replace(match3[0], '').trim();

		return { hasTags: true, tags: photoTags, cleanText: cleanText };
	}
}

export const detectPhotoRequest = (inputText, agentName = '') => {
	const wordCount = inputText.trim().split(/\s+/).length;
	if (wordCount < 2) {
		return { match: false };
	}

	// HARD CODED FAILS
	if (inputText.toLowerCase() === 'nice glamour photo') {
		return { match: false };
	}
	if (inputText.toLowerCase() === 'nice cosplay photo') {
		return { match: false };
	}
	if (inputText.toLowerCase() === 'nice flirty photo') {
		return { match: false };
	}
	if (inputText.toLowerCase() === 'nice intimate photo') {
		return { match: false };
	}
	if (inputText.toLowerCase() === 'nice sexy photo') {
		return { match: false };
	}

	// prettier-ignore
	const searchPhrases = [
    'hey, send me a photo',
    'hey, take a photo',
    'hey, take a selfie',
    'hey, send a photo',
    'send me a photo',
    'send me a selfie',
    'take a photo',
    'take a selfie',
    'send selfie',
    'send photo',
    'take a photo, please',
    'send a photo, please',
    'take a photo for me, please',
    'send a photo for me, please',
    'take a selfie for me, please',
    'send a selfie for me, please',
    'can you take a selfie',
    'can you take a photo',
    'can you send a photo',
    'can you send a selfie',
    'can you take a photo, please',
    'can you send a photo, please',
    'can you take a selfie, please',
    'can you send a selfie, please',
    `${agentName}, send me a photo`,
    `${agentName}, send me a selfie`,
    `${agentName}, take a photo`,
    `${agentName}, take a selfie`,
  ];

	// prettier-ignore
	const searchPhrases1 = [
    'take a glamour photo',
    'hey, send me a glamour photo',
    'hey, take a glamour photo',
    'hey, send a glamour photo',
    'send me a glamour photo',
    'send glamour photo',
    'take a glamour photo, please',
    'send a glamour photo, please',
    'take a glamour photo for me, please',
    'can you take a glamour photo',
    'can you send a glamour photo',
    'can you take a glamour photo, please',
    'can you send a glamour photo, please',
    `${agentName}, send me a glamour photo`,
    `${agentName}, take a glamour photo`,
  ];

	// prettier-ignore
	const searchPhrases2 = [
    'take a cosplay photo',
    'hey, send me a cosplay photo',
    'hey, take a cosplay photo',
    'hey, send a cosplay photo',
    'send me a cosplay photo',
    'send cosplay photo',
    'take a cosplay photo, please',
    'send a cosplay photo, please',
    'take a cosplay photo for me, please',
    'can you take a cosplay photo',
    'can you send a cosplay photo',
    'can you take a cosplay photo, please',
    'can you send a cosplay photo, please',
    `${agentName}, send me a cosplay photo`,
    `${agentName}, take a cosplay photo`,
  ];

	const searchPhrases3 = [
		'take a flirty photo',
		'hey, send me a flirty photo',
		'hey, take a flirty photo',
		'hey, send a flirty photo',
		'send me a flirty photo',
		'send flirty photo',
		'take a flirty photo, please',
		'send a flirty photo, please',
		'take a flirty photo for me, please',
		'can you take a flirty photo',
		'can you send a flirty photo',
		'can you take a flirty photo, please',
		'can you send a flirty photo, please',
		`${agentName}, send me a flirty photo`,
		`${agentName}, take a flirty photo`,
		'take a sexy photo',
		'hey, send me a sexy photo',
		'hey, take a sexy photo',
		'hey, send a sexy photo',
		'send me a sexy photo',
		'send sexy photo',
		'take a sexy photo, please',
		'send a sexy photo, please',
		'take a sexy photo for me, please',
		'can you take a sexy photo',
		'can you send a sexy photo',
		'can you take a sexy photo, please',
		'can you send a sexy photo, please',
		`${agentName}, send me a sexy photo`,
		`${agentName}, take a sexy photo`,
		'take a intimate photo',
		'hey, send me a intimate photo',
		'hey, take a intimate photo',
		'hey, send a intimate photo',
		'send me a intimate photo',
		'send intimate photo',
		'take a intimate photo, please',
		'send a intimate photo, please',
		'take a intimate photo for me, please',
		'can you take a intimate photo',
		'can you send a intimate photo',
		'can you take a intimate photo, please',
		'can you send a intimate photo, please',
		`${agentName}, send me a intimate photo`,
		`${agentName}, take a intimate photo`,
	];

	const phrases = [searchPhrases, searchPhrases1, searchPhrases2, searchPhrases3];
	const { match, option } = findBestMatch(inputText, phrases);
	return { match, option };
};

const findBestMatch = (inputText, phrases) => {
	let bestMatchIndex = -1;
	let bestMatchScore = Infinity;

	phrases.forEach((searchPhrases, index) => {
		const fuse = new Fuse(searchPhrases, {
			includeScore: true,
			threshold: 0.4,
		});

		const searchResults = fuse.search(inputText.toLowerCase());

		// if (searchResults.length > 0) {
		// 	const bestMatchLog = searchResults[0];
		// 	console.log('Best match:', bestMatchLog.item);
		// 	console.log('Score:', bestMatchLog.score);
		// } else {
		// 	console.log('No matches found');
		// }

		if (searchResults.length > 0 && searchResults[0].score < bestMatchScore) {
			bestMatchIndex = index;
			bestMatchScore = searchResults[0].score;
		}
	});

	if (bestMatchScore < 0.4) {
		return { match: true, option: bestMatchIndex };
	} else {
		return { match: false };
	}
};
