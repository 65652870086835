import { useCallback, useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const getFieldType = (value) => {
	if (typeof value === 'string') {
		return 'string';
	} else if (typeof value === 'number') {
		return 'number';
	} else if (Array.isArray(value)) {
		return 'array';
	} else if (typeof value === 'object') {
		return 'object';
	} else if (typeof value === 'boolean') {
		return 'boolean';
	} else {
		return 'unknown';
	}
};

const ControlledInput = ({ value, onChange }) => {
	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const handleChange = (e) => {
		const newValue = e.target.value;
		setInputValue(newValue);
		onChange(newValue);
	};

	return <input className="w-full" type="text" value={inputValue} onChange={handleChange} />;
};

const AgentEditor = () => {
	const [agents, setAgents] = useState([]);
	const [selectedAgent, setSelectedAgent] = useState(null);
	const [fieldValue, setFieldValue] = useState('');
	const [selectedFieldType, setSelectedFieldType] = useState('');
	const [selectedField, setSelectedField] = useState('');
	const [selectedCollection, setSelectedCollection] = useState('agents');

	const fetchAgents = useCallback(async () => {
		const agentsData = await getDocs(collection(db, selectedCollection));
		setAgents(agentsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
	}, [selectedCollection]);

	useEffect(() => {
		fetchAgents();
	}, [fetchAgents, selectedCollection]);

	const handleAgentChange = (e) => {
		const agentId = e.target.value;
		const agent = agents.find((a) => a.id === agentId);
		setSelectedAgent(agent);
		handleFieldChange({ target: { value: '' } });
	};

	const handleCollectionChange = (e) => {
		setSelectedCollection(e.target.value);
	};

	const handleFieldChange = (e) => {
		const field = e.target.value;
		setSelectedField(field);
		if (selectedAgent) {
			const fieldType = getFieldType(selectedAgent[field]);
			console.log(fieldType);
			setSelectedFieldType(fieldType);
			setFieldValue(selectedAgent[field]);
		}
	};

	const updateNestedValue = (obj, keyPath, value) => {
		const keys = keyPath.split('.');
		const lastKey = keys.pop();

		let currentObj = obj;
		keys.forEach((key) => {
			if (!currentObj[key]) {
				currentObj[key] = {};
			}
			currentObj = currentObj[key];
		});

		currentObj[lastKey] = value;
	};

	const handleValueChange = (e, keyPath) => {
		const inputValue = e.target.value;
		let value;

		if (selectedFieldType === 'boolean') {
			value = inputValue === 'true';
		} else {
			const parsedValue = Number(inputValue);
			value = isNaN(parsedValue) ? inputValue : parsedValue;
		}

		console.log(value);
		if (keyPath) {
			const newFieldValue = { ...fieldValue };
			updateNestedValue(newFieldValue, keyPath, value);
			setFieldValue(newFieldValue);
		} else {
			if (selectedFieldType === 'array') {
				setFieldValue(inputValue.split(','));
			} else {
				setFieldValue(value);
			}
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (selectedAgent && selectedField) {
			const agentRef = doc(db, 'agents', selectedAgent.id);
			await updateDoc(agentRef, { [selectedField]: fieldValue });
		}
	};

	const renderInputField = () => {
		if (selectedField && selectedAgent) {
			if (selectedFieldType === 'object') {
				return (
					<textarea
						className="w-full"
						rows={36}
						value={JSON.stringify(fieldValue, null, 2)}
						onChange={(e) => {
							try {
								setFieldValue(JSON.parse(e.target.value));
							} catch (err) {
								console.error('Invalid JSON:', err);
							}
						}}
					/>
				);
			} else {
				return <ControlledInput value={fieldValue} onChange={(newValue) => handleValueChange({ target: { value: newValue } }, '')} />;
			}
		}
		return null;
	};

	return (
		<div className="mx-auto bg-gray-400 absolute top-12 left-0 text-left p-8 w-screen">
			<h1 className="text-xl font-bold mb-4">Agent Editor</h1>
			<form onSubmit={handleSubmit}>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2">Select Collection:</label>
					<select className="" value={selectedCollection} onChange={(e) => handleCollectionChange(e)}>
						<option value="agents">Agents</option>
						<option value="imageGen">ImageGen</option>
					</select>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2">Select Agent:</label>
					<select className="" value={selectedAgent?.id || ''} onChange={handleAgentChange}>
						<option value="">Select an agent</option>
						{agents.map((agent) => (
							<option key={agent.id} value={agent.id}>
								{agent.name}
							</option>
						))}
					</select>
				</div>
				{selectedAgent && (
					<>
						<div className="mb-4">
							<label className="block text-gray-700 text-sm font-bold mb-2">Select Field:</label>
							<select className="" value={selectedField} onChange={handleFieldChange}>
								<option value="">Select a field</option>
								{Object.keys(selectedAgent).map((field) => (
									<option key={field} value={field}>
										{field}
									</option>
								))}
							</select>
						</div>
						<div className="mb-4">
							<label className="block text-gray-700 text-sm font-bold mb-2">Value ({selectedFieldType}):</label>
							{renderInputField()}
						</div>
						<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
							Update
						</button>
					</>
				)}
			</form>
		</div>
	);
};

export default AgentEditor;
