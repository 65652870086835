import { useState } from 'react';
import { Spin } from 'react-cssfx-loading';
import { roleplayModeRequirements } from '../../../constants/constants';
import { useAgent } from '../../../context/AgentContext';
import { callChangeRoleplayMode } from '../../../context/agentUtils';
import IconCircleTick from '../../icons/IconCircleTick';
import IconPadlock from '../../icons/IconArrowPadlock';

const RoleplayModeButton = ({ userAgent, text, newRoleplayMode }) => {
	const [isSaving, setIsSaving] = useState(false);
	const isCurrent = newRoleplayMode === userAgent.roleplayMode;
	const requirement = roleplayModeRequirements.find((req) => req.status === newRoleplayMode);
	const isAvailable = userAgent.xp >= requirement.minXP;

	const handleChangeRoleplayMode = async (agentId, newRoleplayMode) => {
		setIsSaving(true);
		await callChangeRoleplayMode(agentId, newRoleplayMode);
		setIsSaving(false);
	};

	return (
		<button
			disabled={isCurrent || !isAvailable || isSaving}
			onClick={() => handleChangeRoleplayMode(userAgent.agentId, newRoleplayMode)}
			className={`flex justify-center items-center mx-1 w-24 shadow rounded px-1 py-1 text-xs font-medium text-center text-white border ${
				isCurrent ? 'cursor-default border-green-500' : isAvailable ? 'bg-blue-600 hover:bg-blue-800  border-blue-500' : 'bg-blue-400  border-blue-500'
			} `}
		>
			{isSaving && <Spin style={{ marginRight: '4px' }} color="#ffffff" width="12px" height="12px" duration="3s" />}
			{!isSaving && isCurrent && <IconCircleTick className={'w-3 h-3 mr-1 text-green-300'} />}
			{!isSaving && !isCurrent && isAvailable && <IconCircleTick className={'w-3 h-3 mr-1 opacity-30'} />}
			{!isSaving && !isAvailable && <IconPadlock className={'w-3 h-3 mr-1'} />}
			{text}
		</button>
	);
};

const RoleplayModeButtons = () => {
	const { userAgent } = useAgent();

	return (
		<div className="mb-8">
			<p>Roleplay Mode: </p>
			<div className="flex items-center">
				<RoleplayModeButton text="PG13" newRoleplayMode={0} userAgent={userAgent} />
				<RoleplayModeButton text="Limitless" newRoleplayMode={1} userAgent={userAgent} />
			</div>
		</div>
	);
};

export default RoleplayModeButtons;
