export const capitalizeFirstLetter = (str) => {
	// Check if the input is a valid string
	if (typeof str !== 'string' || str.length === 0) {
		// If the input is not a valid string or is empty, return an empty string or a default value
		return '';
	}

	// If the input is a valid string, capitalize the first letter and return the result
	return str.charAt(0).toUpperCase() + str.slice(1);
};
