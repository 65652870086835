import { useState } from 'react';
import { callChangeDisplayName } from '../../context/userUtils';
import { useUser } from '../../context/UserContext';
import { badWords } from '../utils/badWords';
import { Spin } from 'react-cssfx-loading';
import IconCross from '../icons/IconCross';

const ChangeDisplayName = ({ closePortal }) => {
	const { uid, displayName, refetch } = useUser();
	const [isSaving, setIsSaving] = useState(false);
	const [input, setInput] = useState('');
	const [error, setError] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();

		const regex = /^[A-Za-z0-9]+$/;
		const includesBadWord = badWords.some((word) => input.toLowerCase().includes(word.toLowerCase()));

		if (input.trim() === '') {
			setError('Please enter a valid name');
		} else if (input.length > 32) {
			setError('Name must be less than 32 characters');
		} else if (input.includes(' ')) {
			setError('Name cannot contain spaces');
		} else if (!regex.test(input)) {
			setError('Name can only contain alphanumeric characters');
		} else if (includesBadWord) {
			setError('Name contains inappropriate language');
		} else {
			setIsSaving(true);
			await callChangeDisplayName(uid, input);
			await refetch();
			setIsSaving(false);
			closePortal();
		}
	};

	return (
		<div className="flex justify-center items-center">
			<div onClick={closePortal} className="fixed w-full h-full top-0 left-0 z-50 bg-opacity-50 bg-black"></div>
			<div id="changedisplayname-modal" tabIndex="-1" aria-hidden="true" style={{ maxWidth: '650px', maxHeight: '90vh' }} className="fixed top-2/4 -translate-y-2/4 w-96 p-2 sm:p-4 z-50">
				<div className="relative w-full h-full max-w-md md:h-auto">
					<div className="relative bg-slate-200 rounded-lg shadow dark:bg-gray-700">
						<button
							onClick={closePortal}
							type="button"
							className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
							data-modal-hide="changedisplayname-modal"
						>
							<IconCross />
						</button>
						<div className="px-6 py-6 lg:px-8">
							<h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Change Display Name</h3>
							<form className="space-y-6" onSubmit={handleSubmit}>
								<div>
									<input
										type="text"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
										value={input}
										onChange={(e) => setInput(e.target.value)}
										placeholder={displayName}
										required
									/>
								</div>

								<button
									disabled={isSaving}
									type="submit"
									className={`w-full flex justify-center items-center text-white bg-blue-700  ${
										isSaving ? 'cursor-default' : 'hover:bg-blue-800 '
									}  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center `}
								>
									<div className="fixed -translate-x-10">{isSaving && <Spin color="#ffffff" width="12px" height="12px" duration="3s" />}</div>
									Confirm
								</button>
								<p className="text-red-500 text-xs italic">{error}</p>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangeDisplayName;
