import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideMenu from './SideMenu';
import { PortalWithState } from 'react-portal';
import { useUser } from '../context/UserContext';
import SignInModal from '../components/SignInModal';
import IconProfile from '../components/icons/IconProfile';

const Navbar = () => {
	const navigate = useNavigate();

	const location = useLocation();
	const isLobby = location.pathname === '/lobby';
	const isLanding = location.pathname === '/';

	const handleNavigation = () => {
		const path = isLobby || isLanding ? '/' : '/lobby';
		navigate(path);
	};

	const { user } = useUser();

	return (
		<nav style={{ zIndex: '50' }} className="fixed w-full font-sans bg-slate-900 h-10 sm:h-12">
			<div className="w-full h-full flex items-center justify-between">
				<button onClick={handleNavigation} className="flex items-center text-white no-underline hover:no-underline font-bold text-md md:text-lg mx-2 md:mx-4" href="/">
					Synth<span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-purple-500">Love</span>
				</button>
				<div className="flex-grow"></div>
				{user === null && (
					<PortalWithState closeOnEsc>
						{({ openPortal, closePortal, isOpen, portal }) => (
							<>
								<button
									onClick={openPortal}
									className="flex opacity-80 hover:opacity-100 hover:bg-white hover:bg-opacity-25 items-center text-white no-underline hover:no-underline font-bold text-sm md:text-md mx-2 md:mx-4 px-2 py-0 md:py-1 md:px-3 border border-gray-600 rounded"
								>
									Sign In
								</button>
								{portal(<SignInModal closePortal={closePortal} />)}
							</>
						)}
					</PortalWithState>
				)}

				{user !== null && (
					<PortalWithState closeOnEsc>
						{({ openPortal, closePortal, isOpen, portal }) => (
							<>
								<button onClick={openPortal} className="text-gray-400 p-2 mx-2 focus:outline-none hover:text-gray-100" type="button">
									<IconProfile />
								</button>
								{portal(<SideMenu closePortal={closePortal} />)}
							</>
						)}
					</PortalWithState>
				)}
			</div>
		</nav>
	);
};

export default Navbar;
