import { collection, getDocs, query, doc, where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, db } from '../firebase';
import { toast } from 'react-toastify';

export const fetchAgents = async () => {
	const agents = [];

	try {
		const agentsRef = collection(db, 'agents');
		const q = query(agentsRef, where('public', '==', true));
		const querySnapshot = await getDocs(q);

		querySnapshot.forEach((doc) => {
			agents.push({ ...doc.data(), id: doc.id });
		});
	} catch (error) {
		console.log(error);
	}

	return agents;
};

export const getUserAgentDocRef = (uid, agentId) => {
	if (uid && agentId) {
		return doc(db, `users/${uid}/agents`, agentId);
	}
};

export const callChangeContextLength = async (agentId, newContextLength) => {
	try {
		const functions = getFunctions(app);
		const changeContextLengthFunction = httpsCallable(functions, 'changeContextLength');
		const response = await changeContextLengthFunction({
			agentId: agentId,
			contextLength: newContextLength,
		});

		toast('Context length Updated!', { toastId: 'contextLength', type: 'success', theme: 'colored' });
		console.log('Cloud Function response:', response.data);
	} catch (error) {
		console.error('Error calling Firebase Cloud Function:', error);
	}
};

export const callChangeVolatility = async (userAgent, newCurrent) => {
	try {
		if (userAgent.emotionalVolatility.current !== newCurrent) {
			const functions = getFunctions(app);
			const increaseRangeFunction = httpsCallable(functions, 'changeVolatility');
			const response = await increaseRangeFunction({
				agentId: userAgent.agentId,
				newCurrent,
			});

			toast(`EV Changes Updated!`, { toastId: 'ev', type: 'success', theme: 'colored' });
			console.log('Cloud Function response:', response.data);
		}
	} catch (error) {
		console.error('Error calling Firebase Cloud Function:', error);
	}
};

export const callChangeEmotionalWeight = async (userAgent, nestedFieldName, newWeight) => {
	try {
		if (userAgent['emotionalStates'][nestedFieldName].weight !== newWeight) {
			const functions = getFunctions(app);
			const changeWeightFunction = httpsCallable(functions, 'changeWeight');
			const response = await changeWeightFunction({
				agentId: userAgent.agentId,
				nestedFieldName,
				newWeight,
			});

			toast(`EW Changes Updated!`, { toastId: 'ew', type: 'success', theme: 'colored' });
			console.log('Cloud Function response:', response.data);
		}
	} catch (error) {
		console.error('Error calling Firebase Cloud Function:', error);
	}
};

export const callIncreaseRange = async (agentId, fieldName, nestedFieldName) => {
	try {
		const functions = getFunctions(app);
		const increaseRangeFunction = httpsCallable(functions, 'increaseRange');
		const response = await increaseRangeFunction({
			agentId: agentId,
			fieldName: fieldName,
			nestedFieldName: nestedFieldName,
		});

		toast(`Expand Ranges Success!`, { toastId: 'er', type: 'success', theme: 'colored' });
		console.log('Cloud Function response:', response.data);
	} catch (error) {
		console.error('Error calling increaseRange Cloud Function:', error);
	}
};

export const callChangeRoleplayMode = async (agentId, newRoleplayMode) => {
	try {
		const functions = getFunctions(app);
		const changeRoleplayMode = httpsCallable(functions, 'changeRoleplayMode');
		const result = await changeRoleplayMode({ agentId, roleplayMode: newRoleplayMode });
		toast(`Roleplay Mode Updated!`, { toastId: 'rm', type: 'success', theme: 'colored' });
		console.log(result.data.message);
	} catch (error) {
		console.error('Error updating roleplayMode:', error);
	}
};

export const callChangeRelationship = async (agentId, newRelationship) => {
	try {
		const functions = getFunctions(app);
		const changeRelationship = httpsCallable(functions, 'changeRelationship');
		const result = await changeRelationship({ agentId, relationship: newRelationship });
		toast(`Relationship Updated!`, { toastId: 'rs', type: 'success', theme: 'colored' });
		console.log(result.data.message);
	} catch (error) {
		console.error('Error updating relationship:', error);
	}
};
