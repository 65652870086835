import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useAgent } from '../../../context/AgentContext';
import ExpandButton from './ExpandButton';

const getMin = (defaultValue, availableRange) => {
	return defaultValue - availableRange < 0 ? 0 : defaultValue - availableRange;
};
const getMax = (defaultValue, availableRange) => {
	return defaultValue + availableRange > 20 ? 20 : defaultValue + availableRange;
};
const EmotionalWeightSlider = ({ emotionName }) => {
	const { updateEmotionalWeight, userAgent } = useAgent();
	const { emotionalStates } = userAgent;
	const { default: defaultValue, weight: currentValue, range: availableRange } = emotionalStates[emotionName];

	const [min, setMin] = useState(getMin(defaultValue, availableRange));
	const [max, setMax] = useState(getMax(defaultValue, availableRange));
	const [localValue, setLocalValue] = useState(currentValue);
	const [value, setValue] = useState([min, currentValue, max]);

	const handleSliderChange = (newValue) => {
		const constraintValue = [min, newValue[1], max];
		updateEmotionalWeight(emotionName, newValue[1]);
		setLocalValue(newValue[1]);
		setValue(constraintValue);
	};

	useEffect(() => {
		let newMin = getMin(defaultValue, availableRange);
		let newMax = getMax(defaultValue, availableRange);
		setMin(newMin);
		setMax(newMax);
		setValue([newMin, localValue, newMax]);
	}, [userAgent, defaultValue, localValue, availableRange]);

	const currentHandle = {
		position: 'absolute',
		transform: 'translate(-50%, -22%)',
		width: '20px',
		height: '20px',
		cursor: 'pointer',
		zIndex: '500',
		border: 'none',
	};
	const rangeHandle = {
		backgroundColor: 'rgb(226, 232, 240)',
		width: '1px',
		border: 'none',
	};

	return (
		<div className="flex items-center justify-center mx-2 bg-sla">
			<Slider
				keyboard={false}
				style={{ margin: '0 10px' }}
				trackStyle={{ backgroundColor: 'rgb(226, 232, 240)', height: 1 }}
				railStyle={{ backgroundColor: 'rgb(100 116 139)', height: 1 }}
				handleStyle={[rangeHandle, currentHandle, rangeHandle]}
				min={0}
				max={20}
				step={1}
				range
				value={value}
				onChange={handleSliderChange}
				allowCross={false}
			/>
			<ExpandButton fieldName={'emotionalStates'} nestedFieldName={emotionName} />
		</div>
	);
};

export default EmotionalWeightSlider;
