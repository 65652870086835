import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useAgent } from '../../../context/AgentContext';
import ExpandButton from './ExpandButton';

const getMin = (defaultValue, availableRange, increment = 0.0) => {
	return defaultValue - availableRange < 0 ? 0 : parseFloat(defaultValue - availableRange - increment).toFixed(1);
};
const getMax = (defaultValue, availableRange, increment = 0.0) => {
	return defaultValue + availableRange > 1 ? 1 : parseFloat(defaultValue + availableRange + increment).toFixed(1);
};

const EmotionalVolatilitySlider = () => {
	const { updateEmotionalVolatility, userAgent } = useAgent();
	const { emotionalVolatility } = userAgent;
	const { default: defaultValue, current: currentValue, range: availableRange } = emotionalVolatility;
	const [min, setMin] = useState(getMin(defaultValue, availableRange));
	const [max, setMax] = useState(getMax(defaultValue, availableRange));
	const [value, setValue] = useState([min, currentValue, max]);

	const handleSliderChange = (newValue) => {
		const constraintValue = [min, newValue[1], max];
		updateEmotionalVolatility(newValue[1]);
		setValue(constraintValue);
	};

	useEffect(() => {
		let newMin = getMin(defaultValue, availableRange);
		let newMax = getMax(defaultValue, availableRange);
		setMin(newMin);
		setMax(newMax);
		setValue([newMin, currentValue, newMax]);
	}, [userAgent, defaultValue, currentValue, availableRange]);

	const currentHandle = {
		position: 'absolute',
		transform: 'translate(-50%, -22%)',
		width: '20px',
		height: '20px',
		cursor: 'pointer',
		zIndex: '500',
		border: 'none',
	};
	const rangeHandle = {
		backgroundColor: 'rgb(226, 232, 240)',
		width: '1px',
		border: 'none',
	};

	return (
		<div className="mb-8">
			<div className="flex items-center">
				<span>Emotional Volatility:</span>
			</div>
			<div className="flex items-center justify-center mx-2">
				<Slider
					keyboard={false}
					style={{ margin: '0 10px' }}
					trackStyle={{ backgroundColor: 'rgb(226, 232, 240)', height: 1 }}
					railStyle={{ backgroundColor: 'rgb(100 116 139)', height: 1 }}
					handleStyle={[rangeHandle, currentHandle, rangeHandle]}
					min={0}
					max={1}
					step={0.1}
					range
					value={value}
					onChange={handleSliderChange}
					allowCross={false}
				/>
				<ExpandButton fieldName={'emotionalVolatility'} nestedFiledName={null} />
			</div>
		</div>
	);
};

export default EmotionalVolatilitySlider;
