export const relationshipRequirements = [
	{ status: -1, minXP: 0, name: 'Haters' },
	{ status: 0, minXP: 0, name: 'Strangers' },
	{ status: 1, minXP: 500, name: 'Friends' },
	{ status: 2, minXP: 1000, name: 'Lovers' },
];

export const roleplayModeRequirements = [
	{ status: 0, minXP: 0, name: 'PG13' }, // PG13
	{ status: 1, minXP: 750, name: 'Limitless' }, // R18
];

const sltCosts = [{ emotionalStates: 100 }, { emotionalVolatility: 100 }, { selfie: 40 }, { glamour: 80 }, { cosplay: 120 }, { flirty: 200 }, { surprise: 200 }];
const emotionRanges = [{ emotionalStates: 20 }, { emotionalVolatility: 1 }];

const costTypeMapping = {
	0: 'selfie',
	1: 'glamour',
	2: 'cosplay',
	3: 'flirty',
	4: 'surprise',
};

export const findCost = (costType) => {
	if (typeof costType === 'number') {
		costType = costTypeMapping[costType];
	}

	const costObject = sltCosts.find((cost) => cost[costType]);
	return costObject ? costObject[costType] : null;
};

export const findMaxRange = (rangeType) => {
	const rangeObject = emotionRanges.find((range) => range[rangeType]);
	return rangeObject ? rangeObject[rangeType] : null;
};

export const systemMessageTemplate = {
	sendPhotoMessage: 'sendPhotoMessage',
};
