import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../firebase';
import { toast } from 'react-toastify';

export const callChangeDisplayName = async (userId, newDisplayName) => {
	try {
		const functions = getFunctions(app);
		const changeDisplayNameFunction = httpsCallable(functions, 'changeDisplayName');

		const response = await changeDisplayNameFunction({
			userId,
			displayName: newDisplayName,
		});

		toast('Display name updated!', { toastId: 'displayName', type: 'success', theme: 'colored' });
		console.log('Cloud Function response:', response.data);
	} catch (error) {
		console.error('Error calling Firebase Cloud Function:', error);
	}
};
