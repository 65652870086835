import { useState } from 'react';
import { Spin } from 'react-cssfx-loading';
import { findCost, findMaxRange } from '../../../constants/constants';
import { useAgent } from '../../../context/AgentContext';
import { callIncreaseRange } from '../../../context/agentUtils';
import IconArrowDoubleRight from '../../icons/IconArrowDoubleRight';
import IconPadlock from '../../icons/IconArrowPadlock';

function checkRange(obj, upperBoundLimit) {
	const { default: defaultValue, range } = obj;
	const upperBound = defaultValue + range;
	const lowerBound = defaultValue - range;

	return upperBound >= upperBoundLimit && lowerBound <= 0;
}

const ExpandButton = ({ fieldName, nestedFieldName }) => {
	const [isSaving, setIsSaving] = useState(false);
	const { userAgent } = useAgent({ fieldName, nestedFieldName });
	const cost = findCost(fieldName);
	const isAtRange = nestedFieldName ? checkRange(userAgent[fieldName][nestedFieldName], findMaxRange(fieldName)) : checkRange(userAgent[fieldName], findMaxRange(fieldName));
	const isAvailable = cost !== null && userAgent.availablePoints >= cost && !isAtRange;

	const handleIncreaseRange = async () => {
		setIsSaving(true);
		await callIncreaseRange(userAgent.agentId, fieldName, nestedFieldName);
		setIsSaving(false);
	};

	return (
		<button
			disabled={!isAvailable || isSaving}
			onClick={handleIncreaseRange}
			className={`flex justify-center items-center ml-4 w-24 shadow rounded px-1 py-1 text-xs font-medium text-center text-white border ${
				isAvailable ? 'bg-blue-600 hover:bg-blue-800  border-blue-500' : 'bg-blue-400  border-blue-500'
			}`}
		>
			{isSaving && <Spin style={{ marginRight: '4px' }} color="#ffffff" width="12px" height="12px" duration="3s" />}
			{!isSaving && isAvailable && <IconArrowDoubleRight className={'w-3 h-3 mr-1 opacity-30'} />}
			{!isSaving && !isAvailable && <IconPadlock className={'w-3 h-3 mr-1'} />}
			Expand
		</button>
	);
};

export default ExpandButton;
