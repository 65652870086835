import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AgentProvider } from './context/AgentContext';
import { UserProvider } from './context/UserContext';
import Lobby from './pages/Lobby';
import PrivateRoute from './layout/PrivateRoute';
import Layout from './layout/Layout';
import ChatRoom from './pages/ChatRoom';
import Gallery from './pages/Gallery';
import Landing from './pages/Landing';
import ImageFull from './pages/ImageFull';
import ChatLog from './pages/ChatLog';
import AgentEditor from './pages/admin/AgentEditor';
import AgentFieldAdder from './pages/admin/AgentFieldAdder';

const queryClient = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<UserProvider>
					<AgentProvider>
						<Routes>
							<Route element={<Layout />}>
								<Route path="/" element={<Landing />} />
								<Route path="/lobby" element={<Lobby />} />
								<Route path="/editor" element={<AgentEditor />} />
								<Route path="/adder" element={<AgentFieldAdder />} />
								<Route element={<PrivateRoute />}>
									<Route path="/room/:id" element={<ChatRoom />} />
									<Route path="/room/:id/chatlog" element={<ChatLog />} />
									<Route path="/room/:id/gallery" element={<Gallery />} />
									<Route path="/room/:id/gallery/:seed" element={<Gallery />} />
									<Route path="/room/:id/imagefull/:docId" element={<ImageFull />} />
								</Route>
							</Route>
						</Routes>
					</AgentProvider>
				</UserProvider>
			</BrowserRouter>
			{/* <ReactQueryDevtools initialIsOpen={false} /> */}
		</QueryClientProvider>
	);
}

export default App;
