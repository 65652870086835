import { PortalWithState } from 'react-portal';
import { auth } from '../firebase';
import ChangeDisplayName from '../components/userOptions/ChangeDisplayName';
import UpdateProfilePicture from '../components/userOptions/UpdateProfilePicture';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { useEffect } from 'react';
import IconCross from '../components/icons/IconCross';
import IconSignout from '../components/icons/IconSignout';
import IconLobby from '../components/icons/IconLobby';
import IconCredits from '../components/icons/IconCredits';
import IconEdit from '../components/icons/IconEdit';
import IconProfilePicture from '../components/icons/IconProfilePicture';
import IconHelp from '../components/icons/IconHelp';

const SideMenu = ({ closePortal }) => {
	const navigate = useNavigate();
	const { userData, refetch } = useUser();

	useEffect(() => {
		refetch();
	}, [refetch]);

	const handleClick = (path) => {
		navigate(path);
		closePortal();
	};

	return (
		<div>
			<div onClick={closePortal} className="fixed w-full h-full top-0 left-0 z-50 bg-opacity-50 bg-black"></div>
			<div className="fixed bg-slate-300 top-0 left-0 z-50 h-screen px-4 overflow-y-auto w-80 text-gray-900">
				<div className="flex justify-between items-center">
					<span className="flex-grow"></span>
					<button type="button" onClick={closePortal} className="text-gray-600 bg-transparent focus:outline-none hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 my-2">
						<IconCross />
					</button>
				</div>

				<div className="py-4 overflow-y-auto">
					<ul className="space-y-2 font-medium">
						<li onClick={() => auth.signOut()}>
							<a href="/" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
								<IconSignout />
								<span className="ml-3">Sign Out</span>
							</a>
						</li>

						<li>
							<button onClick={() => handleClick('/lobby')} className="flex items-center w-full text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
								<IconLobby />
								<span className="flex-1 ml-3 whitespace-nowrap">Lobby</span>
							</button>
						</li>
						<hr className="border border-gray-500 border-t-0" />
						<li>
							<button className="mt-8 overflow-hidden flex items-center w-full text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
								<IconCredits />
								<span className="flex-1 ml-3 whitespace-nowrap">Credits: {Math.round(userData.tokens / 10)}</span>
								<span className="inline-flex items-center justify-center px-4 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">Recharge</span>
							</button>
						</li>
						<li>
							<PortalWithState closeOnEsc>
								{({ openPortal, closePortal, isOpen, portal }) => (
									<>
										<button onClick={openPortal} className=" flex items-center w-full text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
											<IconEdit />
											<span className="flex-1 ml-3 whitespace-nowrap">Change Display Name</span>
										</button>
										{portal(<ChangeDisplayName closePortal={closePortal} />)}
									</>
								)}
							</PortalWithState>
						</li>
						<li>
							<PortalWithState closeOnEsc>
								{({ openPortal, closePortal, isOpen, portal }) => (
									<>
										<button onClick={openPortal} className="flex items-center w-full text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
											<IconProfilePicture />
											<span className="flex-1 ml-3 whitespace-nowrap">Update Profile Picture</span>
										</button>
										{portal(<UpdateProfilePicture closePortal={closePortal} />)}
									</>
								)}
							</PortalWithState>
						</li>

						<hr className="border border-gray-500 border-t-0" />
						<li>
							<button className="mt-8 flex items-center w-full text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
								<IconHelp />
								<span className="flex-1 ml-3 whitespace-nowrap">Help</span>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default SideMenu;
