import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, deleteDoc, collection, where, getDocs, query } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { useUser } from '../context/UserContext';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import IconDelete from '../components/icons/IconDelete';
import IconTick from '../components/icons/IconTick';
import IconCross from '../components/icons/IconCross';
import { deleteObject, ref } from 'firebase/storage';

const ImageFull = () => {
	const { uid } = useUser();
	const { id: agentId, docId } = useParams();
	const navigate = useNavigate();
	const [mediaItem, setMediaItem] = useState(null);
	const [confirmDelete, setConfirmDelete] = useState(false);

	useEffect(() => {
		if (uid && agentId && docId) {
			const fetchMediaItem = async () => {
				const mediaRef = collection(db, `users/${uid}/agents/${agentId}/media`);
				const q = query(mediaRef, where('messageId', '==', docId));
				const querySnapshot = await getDocs(q);

				querySnapshot.forEach((doc) => {
					setMediaItem({ ...doc.data(), id: doc.id });
				});
			};
			fetchMediaItem();
		}
	}, [uid, agentId, docId]);

	const handleDelete = async () => {
		if (!confirmDelete) {
			setConfirmDelete(true);
			return;
		}

		if (mediaItem) {
			// Delete from `users/${uid}/agents/${agentId}/media`

			const mediaRef = doc(db, `users/${uid}/agents/${agentId}/media`, mediaItem.id);
			const messageRef = doc(db, `users/${uid}/agents/${agentId}/messages`, docId);
			await deleteDoc(mediaRef);
			await deleteDoc(messageRef);

			// Delete the image from Firebase Storage
			try {
				const storageRef = ref(storage, `messages/${mediaItem.fileName}.jpg`);
				const storageRefThumb = ref(storage, `messages/thumb/${mediaItem.fileName}.jpg`);
				await deleteObject(storageRef);
				await deleteObject(storageRefThumb);
			} catch (error) {
				console.log(error);
			}

			setMediaItem(null);
		}
		setConfirmDelete(false);
		navigate(-1);
	};

	const slide = mediaItem
		? {
				src: mediaItem.imgUrl[0],
				width: mediaItem.width,
				height: mediaItem.height,
		  }
		: null;

	return (
		<>
			{mediaItem && (
				<Lightbox
					slides={[slide]}
					open
					index={0}
					carousel={{ finite: true }}
					close={() => navigate(-1)}
					plugins={[Zoom]}
					zoom={{ scrollToZoom: true, zoomInMultiplier: 2, maxZoomPixelRatio: 1.5 }}
					toolbar={{
						buttons: [
							<button key="delete" className="fixed left-0 bottom-0 my-3 mx-2 text-white opacity-50" onClick={handleDelete}>
								{!confirmDelete ? <IconDelete /> : <IconTick />}
							</button>,
							<button key="close" className="fixed right-0 bottom-0 my-1 mx-1 text-white opacity-50" onClick={() => navigate(-1)}>
								<IconCross className={'w-6 h-6'} />
							</button>,
						],
					}}
				/>
			)}
		</>
	);
};

export default ImageFull;
