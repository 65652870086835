import { useState } from 'react';
import IconEnvelope from './icons/IconEnvelope';
import { sendSignInEmail } from './auth';

const SignIn = () => {
	const [input, setInput] = useState('');
	const [emailSent, setEmailSent] = useState(false);
	const [signInSuccess, setShowSignInSuccess] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const success = await sendSignInEmail(input);

		setEmailSent(true);
		setShowSignInSuccess(success);
	};

	return (
		<div className="sm:bg-slate-800 bg-opacity-50 p-2 sm:p-4 rounded sm:border sm:border-gray-700 h-36 sm:h-44 max-w-xl text-sm sm:text-base ">
			{!emailSent ? (
				<form className="text-white" onSubmit={handleSubmit} style={{ position: 'relative' }}>
					<div className="">
						<label className="text-xs sm:text-base">Sign in / up with Email</label>

						<input
							className="mb-2 sm:mb-4 p-2 w-full placeholder-slate-400 rounded text-black"
							value={input}
							onChange={(e) => setInput(e.target.value)}
							type="email"
							placeholder="somebody@email.com"
						/>

						<button type="submit" className="flex justify-center bg-slate-600 hover:bg-slate-500 p-2 rounded opacity-80 w-full items-center">
							<IconEnvelope className={'w-5 h-5'} />
							<p className="px-2">Email</p>
						</button>
					</div>
				</form>
			) : (
				<div className="text-white bg-slate-700 p-4 sm:p-6 rounded flex items-center">
					<div className="bg-blue-500 rounded p-2 mr-2 sm:mr-4">
						<IconEnvelope className={'w-5 h-5'} />
					</div>
					<div>
						{signInSuccess ? (
							<>
								<p className="text-xs sm:text-base">Check your email for a special login link</p>
								<p className="text-xs text-gray-400">Be sure to check your spam...</p>
							</>
						) : (
							<p onClick={() => setEmailSent(false)} className="cursor-pointer text-xs sm:text-base hover:text-gray-200">
								Error sending the email. Please try again
							</p>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default SignIn;
