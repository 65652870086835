export function resizeImageToJpeg(blob, maxWidth, maxHeight, quality = 0.8) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = URL.createObjectURL(blob);
		img.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			let width = img.width;
			let height = img.height;

			if (width > height) {
				if (width > maxWidth) {
					height *= maxWidth / width;
					width = maxWidth;
				}
			} else {
				if (height > maxHeight) {
					width *= maxHeight / height;
					height = maxHeight;
				}
			}

			canvas.width = width;
			canvas.height = height;
			ctx.drawImage(img, 0, 0, width, height);

			canvas.toBlob(
				(resizedBlob) => {
					resolve(resizedBlob);
				},
				'image/jpeg',
				quality
			);
		};
		img.onerror = (err) => {
			reject(err);
		};
	});
}

export function dataURLToBlob(dataURL) {
	const binaryString = atob(dataURL.split(',')[1]);
	const len = binaryString.length;
	const bytes = new Uint8Array(len);

	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	return new Blob([bytes], { type: 'image/jpeg' });
}
