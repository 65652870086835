import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';

import 'react-image-crop/dist/ReactCrop.css';
import { Spin } from 'react-cssfx-loading';
import { useUser } from '../../context/UserContext';
import { db, storage } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { resizeImageToJpeg } from '../utils/canvasUtils';

function centerAspectCrop(mediaWidth, mediaHeight) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 80,
			},
			1,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
}

export default function UpdateProfilePicture({ closePortal }) {
	const { uid, refetch } = useUser();
	const [imgSrc, setImgSrc] = useState('');
	const [isSaving, setIsSaving] = useState(false);
	const previewCanvasRef = useRef(null);
	const imgRef = useRef(null);
	const [crop, setCrop] = useState();
	const [completedCrop, setCompletedCrop] = useState();

	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined);
			const reader = new FileReader();
			reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
			reader.readAsDataURL(e.target.files[0]);
		}
	}

	function onImageLoad(e) {
		const { width, height } = e.currentTarget;
		setCrop(centerAspectCrop(width, height, 1));
	}

	function onClickUse() {
		setIsSaving(true);
		if (!previewCanvasRef.current) {
			throw new Error('Crop canvas does not exist');
		}

		try {
			previewCanvasRef.current.toBlob(async (blob) => {
				if (!blob) {
					throw new Error('Failed to create blob');
				}

				const resizedBlob = await resizeImageToJpeg(blob, 256, 256, 0.8);

				const storageRef = ref(storage, `/avatars/${uid}`);
				await uploadBytes(storageRef, resizedBlob);

				const downloadURL = await getDownloadURL(storageRef);

				const docRef = doc(db, 'users', uid);
				await updateDoc(docRef, { avatarURL: downloadURL });
				toast('Profile picture updated!', { toastId: 'profilePicture', type: 'success', theme: 'colored' });
				refetch();
				closePortal();
			});
		} catch (error) {
			console.log(error);
		}

		setIsSaving(false);
	}

	useEffect(() => {
		let debounceTimer;

		const updateCanvas = async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
				canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
			}
		};

		if (completedCrop) {
			debounceTimer = setTimeout(updateCanvas, 100);
		}

		return () => {
			clearTimeout(debounceTimer);
		};
	}, [completedCrop]);

	return (
		<div className="flex justify-center items-center text-black">
			<div onClick={closePortal} className="fixed w-full h-full top-0 left-0 z-50 bg-opacity-50 bg-black"></div>
			<div
				id="changedisplayname-modal"
				tabIndex="-1"
				aria-hidden="true"
				style={{ maxWidth: '650px' }}
				className="fixed top-2/4 -translate-y-2/4 p-2 sm:p-4 z-50 bg-slate-200 rounded-lg shadow w-90vw sm:w-60vw "
			>
				<div className="flex justify-between">
					<label className="w-28 flex justify-center cursor-pointer items-center text-white bg-slate-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-4 py-2.5 text-center">
						<input type="file" accept="image/*" onChange={onSelectFile} style={{ display: 'none' }} />
						Choose File
					</label>
					{completedCrop && (
						<>
							<canvas
								ref={previewCanvasRef}
								style={{
									visibility: 'hidden',
									objectFit: 'contain',
									position: 'absolute',
									width: completedCrop.width,
									height: completedCrop.height,
								}}
							/>
							<button
								onClick={onClickUse}
								disabled={isSaving}
								type="submit"
								className={`w-12 flex justify-center items-center text-black ${isSaving ? 'cursor-default' : ''}  font-medium text-sm px-6 py-2.5 text-center `}
							>
								<div className="fixed -translate-x-14">{isSaving && <Spin color="#000000" width="12px" height="12px" duration="3s" />}</div>
								<div className="text-right mr-6">{!isSaving ? 'Use' : 'Uploading'}</div>
							</button>
						</>
					)}
				</div>
				{imgSrc && (
					<ReactCrop
						crop={crop}
						onChange={(_, percentCrop) => setCrop(percentCrop)}
						onComplete={(c) => setCompletedCrop(c)}
						aspect={1}
						style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }}
						imageStyle={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
					>
						<img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} />
					</ReactCrop>
				)}
			</div>
		</div>
	);
}
