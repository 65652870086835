import { db } from '../firebase';
import { useQuery } from '@tanstack/react-query';
import { query, collection, orderBy, limit, getDocs } from 'firebase/firestore';

import { useEffect, useState } from 'react';
import { useUser } from '../context/UserContext';

export function findLatestAgentMessage(messages) {
	let currentEmotionalState = 'unknown';
	let tokenCost = 0;
	for (let i = messages.length - 1; i >= 0; i--) {
		if (messages[i].hasOwnProperty('emotionalState')) {
			currentEmotionalState = messages[i].emotionalState;
			tokenCost = messages[i].tokens;
			break;
		}
	}
	return { currentEmotionalState, tokenCost };
}

const fetchMessages = async ({ queryKey }) => {
	const [, messageRef, queryMsgLimit] = queryKey; // Destructure the userId from the queryKey
	const messages = [];

	const messagesRef = collection(db, messageRef);
	const q = query(messagesRef, limit(queryMsgLimit), orderBy('timestamp', 'desc'));
	const querySnapshot = await getDocs(q);
	querySnapshot.forEach((doc) => {
		messages.push({ ...doc.data(), id: doc.id });
	});

	return messages.reverse();
};

export const useMessages = (agent) => {
	const queryMsgLimit = 20;
	const [isLastMsgUser, setIsLastMsgUser] = useState(false);
	const [isTyping, setIsTyping] = useState(false);
	const [isNewRoom, setIsNewRoom] = useState(false);
	const [messages, setMessages] = useState([]);

	const agentId = agent ? agent.agentId : 'agentId';
	const { uid } = useUser();
	const { data, refetch } = useQuery([`${agentId}messages`, `users/${uid}/agents/${agentId}/messages`, queryMsgLimit], fetchMessages, { enabled: !!agent || !!uid });

	useEffect(() => {
		if (data) {
			if (data.length <= 0) {
				setIsNewRoom(true);
			}
			if (data.length > 0) {
				setIsNewRoom(false);
				if (data[data.length - 1].tokens || data[data.length - 1].system) {
					setIsLastMsgUser(false);
				} else {
					setIsLastMsgUser(true);
				}
			}
			setMessages(data);
		}
	}, [data]);

	useEffect(() => {
		setIsTyping(isLastMsgUser);
	}, [isLastMsgUser]);

	return {
		messages,
		isNewRoom,
		isTyping,
		refetch,
		queryMsgLimit,
	};
};
