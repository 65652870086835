import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, orderBy, startAfter, limit, endBefore } from 'firebase/firestore';
import { db } from '../firebase';
import { useUser } from '../context/UserContext';
import 'yet-another-react-lightbox/styles.css';
import { useInfiniteQuery } from '@tanstack/react-query';
import Message from '../components/messages/Message';
import EmptyChatLog from '../components/EmptyChatLog';
import IconChatLog from '../components/icons/IconChatLog';
import IconExport from '../components/icons/IconExport';
import { exportChatLog } from '../components/utils/messageUtils';
import { useAgent } from '../context/AgentContext';

const fetchMessages = async ({ pageParam = null }, { uid, agentId, qLimit, setQLimit }) => {
	const messagesRef = collection(db, `users/${uid}/agents/${agentId}/messages`);

	const q = pageParam
		? query(
				messagesRef,
				orderBy('timestamp', 'desc'),
				startAfter(pageParam),
				limit(qLimit) // Fetch 10 documents at a time
		  )
		: query(
				messagesRef,
				orderBy('timestamp', 'desc'),
				endBefore(Date.now()),
				limit(qLimit) // Fetch 10 documents at a time
		  );

	if (qLimit < 20) {
		setQLimit(qLimit + 4);
	}

	const querySnapshot = await getDocs(q);
	const messageData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

	if (messageData.length === 0) {
		return { data: messageData, previousTs: null, nextTs: null };
	}

	const previousTs = messageData[0].timestamp;
	let nextTs = messageData[messageData.length - 1].timestamp;

	if (messageData.length < qLimit) {
		nextTs = null;
	}

	return { data: messageData, previousTs, nextTs };
};

const ChatLog = () => {
	const { uid, displayName } = useUser();
	const { id: agentId } = useParams();
	const { userAgent } = useAgent();
	const [messages, setMessages] = useState([]);
	const [qLimit, setQLimit] = useState(4);

	const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
		['chatLog', uid, agentId],
		({ pageParam = 0 }) => fetchMessages({ pageParam }, { uid, agentId, qLimit, setQLimit }),
		{
			getPreviousPageParam: (firstPage) => firstPage.previousTs ?? undefined,
			getNextPageParam: (lastPage) => lastPage.nextTs ?? undefined,
		}
	);

	useEffect(() => {
		if (uid && agentId) {
			if (data && data.pages) {
				const messages = [];
				data.pages.forEach((page) => {
					page.data.forEach((item) => {
						messages.push(item);
					});
				});
				setMessages(messages);
			}
		}
	}, [uid, agentId, data, hasNextPage]);

	return (
		<>
			<main key="main" className="h-screen flex flex-col overflow-y-scroll">
				<div className="z-30">
					{messages.length > 0 && (
						<div className="flex justify-between mx-4">
							<button
								className="text-gray-400 p-2 px-4 my-14 text-xs sm:text-base text-center rounded border border-gray-600 hover:bg-gray-900 flex items-center"
								onClick={() => exportChatLog(uid, agentId, displayName, userAgent.name)}
							>
								<IconExport className="w-4 h-4 inline-block mr-2" />
								Export all
							</button>
							<button
								className="text-gray-400 p-2 px-4 my-14 text-xs sm:text-base text-center rounded border border-gray-600 hover:bg-gray-900 flex items-center"
								onClick={() => fetchNextPage()}
								disabled={!hasNextPage || isFetchingNextPage}
							>
								<IconChatLog className="w-4 h-4 inline-block mr-2" />
								{isFetchingNextPage ? 'Loading...' : hasNextPage ? 'Load previous messages' : 'Nothing more to load'}
							</button>
						</div>
					)}
					{messages &&
						messages
							.slice()
							.reverse()
							.map((message) => <Message key={message.id} message={message} />)}

					{messages.length <= 0 && !isFetching && <EmptyChatLog />}
					{messages.length > 0 && <p className="text-white my-8 text-xs sm:text-base">End of chat log</p>}
				</div>
			</main>
		</>
	);
};

export default ChatLog;
