import { createContext, useContext, useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from '@tanstack/react-query';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { capitalizeFirstLetter } from '../components/utils/capitilizeFirstLetter';

async function fetchDocument(docId) {
	const docRef = doc(db, 'users', docId);
	const docSnapshot = await getDoc(docRef);

	if (!docSnapshot.exists()) {
		throw new Error('Document not found');
	}

	return docSnapshot.data();
}

const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [user] = useAuthState(auth);
	const [displayName, setDisplayName] = useState(undefined);
	const [userData, setUserData] = useState(undefined);
	const [avatarURL, setAvatarURL] = useState(undefined);
	const [contextUser, setContextUser] = useState(null);
	const uid = user ? user.uid : undefined;
	const userMessageRef = `users/${uid}/messages`;

	const { data, isInitialLoading, refetch } = useQuery(['userData', uid], () => fetchDocument(uid), { enabled: !!uid });

	useEffect(() => {
		if (user && !userData) {
			setDisplayName(capitalizeFirstLetter(user.email.split('@')[0]));
		}
		if (userData) {
			setDisplayName(capitalizeFirstLetter(userData.displayName));
			setAvatarURL(userData.avatarURL);
		}
	}, [setDisplayName, userData, user]);

	useEffect(() => {
		if (data && !isInitialLoading) {
			setUserData(data);
		}
	}, [data, isInitialLoading]);

	// New function to update the user in the context
	const updateUser = (newUser) => {
		setContextUser(newUser);
	};

	return (
		<UserContext.Provider
			value={{
				user: contextUser || user, // Use contextUser if it's set, otherwise use user from useAuthState
				updateUser,
				refetch,
				uid,
				displayName,
				avatarURL,
				userMessageRef,
				userData,
				isInitialLoading,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = () => useContext(UserContext);
