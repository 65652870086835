import React, { useState } from 'react';
import { capitalizeFirstLetter } from '../../utils/capitilizeFirstLetter';
import EmotionalWeightSlider from './EmotionalWeightSlider';

const emotionsOrder = [
	'flirty',
	'seductive',
	'snarky',
	'sassy',
	'sarcastic',
	'supportive',
	'whimsical',
	'euphoric',
	'bashful',
	'vulnerable',
	'melancholic',
	'suspicious',
	'zen',
	'erratic',
	'defiant',
	'enraged',
];

const EmotionalWeightsSliders = ({ emotions }) => {
	const [showHidden, setShowHidden] = useState(false);

	const orderedEmotions = emotionsOrder.map((emotionKey) => ({
		key: emotionKey,
		data: emotions[emotionKey],
	}));

	const zeroWeightEmotions = orderedEmotions.filter((emotion) => emotion.data.default === 0 && emotion.data.range === 0);
	const nonZeroWeightEmotions = orderedEmotions.filter((emotion) => !(emotion.data.default === 0 && emotion.data.range === 0));
	const finalOrderedEmotions = [...nonZeroWeightEmotions, ...zeroWeightEmotions];

	return (
		<div>
			{finalOrderedEmotions.map((emotion) => {
				if (emotion.data.default === 0 && emotion.data.range === 0 && !showHidden) {
					return null;
				}
				return (
					<div key={emotion.key} className="mt-2">
						<div className="flex items-center text-xs text-gray-300">
							<span>{capitalizeFirstLetter(emotion.key)}: </span>
						</div>
						<EmotionalWeightSlider emotionName={emotion.key} />
					</div>
				);
			})}
			<button
				onClick={() => setShowHidden(!showHidden)}
				className="flex justify-center items-center ml-4 w-24 shadow rounded my-4 px-1 py-1 text-xs font-medium text-center text-white border ${
				bg-blue-600 hover:bg-blue-800  border-blue-500"
			>
				{showHidden ? 'Show Less' : 'Show More'}
			</button>
		</div>
	);
};

export default EmotionalWeightsSliders;
