import { sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';
import { auth } from '../firebase';

export const sendSignInEmail = async (email) => {
	const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_PROD_URL;

	const actionCodeSettings = {
		url: baseUrl + '/?email=' + email,
		handleCodeInApp: true,
	};

	try {
		await sendSignInLinkToEmail(auth, email, actionCodeSettings);
		window.localStorage.setItem('emailForSignIn', email);
		return true;
	} catch (error) {
		console.error('Error sending email:', error);
		return false;
	}
};

export const completeSignIn = async (email, emailLink) => {
	try {
		const result = await signInWithEmailLink(auth, email, emailLink);
		window.localStorage.removeItem('emailForSignIn');
		return result;
	} catch (error) {
		console.error('Error completing sign-in:', error);
		return null;
	}
};
