import { useState } from 'react';
import { Spin } from 'react-cssfx-loading';
import { relationshipRequirements } from '../../../constants/constants';
import { useAgent } from '../../../context/AgentContext';
import { callChangeRelationship } from '../../../context/agentUtils';
import IconCircleTick from '../../icons/IconCircleTick';
import IconPadlock from '../../icons/IconArrowPadlock';

const RelationshipButton = ({ userAgent, text, newRelationship }) => {
	const [isSaving, setIsSaving] = useState(false);
	const isCurrent = newRelationship === userAgent.relationship;
	const requirement = relationshipRequirements.find((req) => req.status === newRelationship);
	const isAvailable = userAgent.xp >= requirement.minXP;

	const handleChangeRelationship = async (agentId, newRelationship) => {
		setIsSaving(true);
		await callChangeRelationship(agentId, newRelationship);
		setIsSaving(false);
	};

	return (
		<button
			disabled={isCurrent || !isAvailable || isSaving}
			onClick={() => handleChangeRelationship(userAgent.agentId, newRelationship)}
			className={`flex justify-center items-center mx-1 w-24 shadow rounded px-1 py-1 text-xs font-medium text-center text-white border ${
				isCurrent ? 'cursor-default border-green-500' : isAvailable ? 'bg-blue-600 hover:bg-blue-800  border-blue-500' : 'bg-blue-400  border-blue-500'
			} `}
		>
			{isSaving && <Spin style={{ marginRight: '4px' }} color="#ffffff" width="12px" height="12px" duration="3s" />}
			{!isSaving && isCurrent && <IconCircleTick className={'w-3 h-3 mr-1 text-green-300'} />}
			{!isSaving && !isCurrent && isAvailable && <IconCircleTick className={'w-3 h-3 mr-1 opacity-30'} />}
			{!isSaving && !isAvailable && <IconPadlock className={'w-3 h-3 mr-1'} />}
			{text}
		</button>
	);
};

const RelationshipButtons = () => {
	const { userAgent } = useAgent();

	return (
		<div className="mb-8">
			<p>Relationship: </p>
			<div className="flex items-center">
				<RelationshipButton text="Haters" newRelationship={-1} userAgent={userAgent} />
				<RelationshipButton text="Strangers" newRelationship={0} userAgent={userAgent} />
				<RelationshipButton text="Friends" newRelationship={1} userAgent={userAgent} />
				<RelationshipButton text="Lovers" newRelationship={2} userAgent={userAgent} />
			</div>
		</div>
	);
};

export default RelationshipButtons;
