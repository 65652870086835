import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, getDocs, query, orderBy, doc, deleteDoc, startAfter, limit } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { useUser } from '../context/UserContext';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import IconDelete from '../components/icons/IconDelete';
import IconTick from '../components/icons/IconTick';
import IconCross from '../components/icons/IconCross';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { ref, deleteObject } from 'firebase/storage';

const fetchMedia = async ({ pageParam = null }, { uid, agentId }) => {
	const mediaRef = collection(db, `users/${uid}/agents/${agentId}/media`);

	const qLimit = 20;
	const q = query(
		mediaRef,
		orderBy('timestamp', 'desc'),
		startAfter(pageParam ? pageParam : Date.now()),
		limit(qLimit) // Fetch 10 documents at a time
	);

	const querySnapshot = await getDocs(q);
	const mediaData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

	if (mediaData.length === 0) {
		return { data: mediaData, previousTs: null, nextTs: null };
	}

	const previousTs = mediaData[0].timestamp;
	let nextTs = mediaData[mediaData.length - 1].timestamp;

	if (mediaData.length < qLimit) {
		nextTs = null;
	}

	return { data: mediaData, previousTs, nextTs };
};

const Gallery = () => {
	const { uid } = useUser();
	const { id: agentId } = useParams();
	const navigate = useNavigate();
	const [mediaItems, setMediaItems] = useState([]);
	const [index, setIndex] = useState(-1);
	const [confirmDelete, setConfirmDelete] = useState(false);

	const { ref: elementRef, inView } = useInView();

	const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(['media', uid, agentId], ({ pageParam = 0 }) => fetchMedia({ pageParam }, { uid, agentId }), {
		getPreviousPageParam: (firstPage) => firstPage.previousTs ?? undefined,
		getNextPageParam: (lastPage) => lastPage.nextTs ?? undefined,
	});

	useEffect(() => {
		if (uid && agentId) {
			if (data && data.pages) {
				const media = [];
				data.pages.forEach((page) => {
					page.data.forEach((item) => {
						media.push(item);
					});
				});
				setMediaItems(media);
			}
		}
	}, [uid, agentId, data, hasNextPage]);

	const handleSlideView = () => {
		setConfirmDelete(false);
	};

	const photos = mediaItems.map((item) => ({
		src: item.imgUrl[0],
		width: item.width,
		height: item.height,
		images: [
			{
				src: item.imgThumbUrl[0],
				width: item.width / item.thumbnailScale,
				height: item.height / item.thumbnailScale,
			},
		],
	}));

	const slides = photos.map(({ src, width, height }) => ({
		src,
		width,
		height,
	}));

	const handleDelete = async () => {
		if (!confirmDelete) {
			setConfirmDelete(true);
			return;
		}

		if (index >= 0) {
			const itemToDelete = mediaItems[index];

			const mediaRef = doc(db, `users/${uid}/agents/${agentId}/media`, itemToDelete.id);
			const messageRef = doc(db, `users/${uid}/agents/${agentId}/messages`, itemToDelete.messageId);
			await deleteDoc(mediaRef);
			await deleteDoc(messageRef);

			// Delete the image from Firebase Storage
			try {
				const storageRef = ref(storage, `messages/${itemToDelete.fileName}.jpg`);
				const storageRefThumb = ref(storage, `messages/thumb/${itemToDelete.fileName}.jpg`);
				await deleteObject(storageRef);
				await deleteObject(storageRefThumb);
			} catch (error) {
				console.log(error);
			}

			const newMediaItems = mediaItems.filter((item) => item.id !== itemToDelete.id);
			setMediaItems(newMediaItems);
			setIndex(-1);
		}
		setConfirmDelete(false);
		navigate(`/room/${agentId}/gallery`);
	};

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	return (
		<>
			<main key="main" className="sm:pt-12 sm:pb-12 pb-6 pt-10 h-screen flex flex-col overflow-y-scroll">
				<div className="z-50">
					<PhotoAlbum layout="rows" spacing={0} padding={0} targetRowHeight={200} photos={photos} onClick={({ index }) => setIndex(index)} />
					{mediaItems.length <= 0 && !isFetching && <p className="text-gray-400 py-16 text-xs sm:text-base text-center">No photos yet, go ahead and request some!</p>}
					{mediaItems.length > 0 && (
						<button className="text-gray-400 p-2 px-4 m-2 text-xs sm:text-base text-center bg-slate-900" ref={elementRef} onClick={() => fetchNextPage()} disabled={!hasNextPage || isFetchingNextPage}>
							{isFetchingNextPage ? 'Loading more...' : hasNextPage ? 'Load more' : 'Nothing more to load'}
						</button>
					)}
				</div>
				<Lightbox
					slides={slides}
					open={index >= 0}
					index={index}
					close={() => setIndex(-1)}
					plugins={[Zoom]}
					zoom={{ scrollToZoom: true, zoomInMultiplier: 2, maxZoomPixelRatio: 1.5 }}
					on={{
						view: handleSlideView,
					}}
					toolbar={{
						buttons: [
							<button key="delete" className="fixed left-0 bottom-0 my-3 mx-2 text-white opacity-50" onClick={handleDelete}>
								{!confirmDelete ? <IconDelete /> : <IconTick />}
							</button>,
							<button key="close" className="fixed right-0 bottom-0 my-1 mx-1 text-white opacity-50" onClick={() => setIndex(-1)}>
								<IconCross className={'w-8 h-8'} />
							</button>,
						],
					}}
				/>
			</main>
		</>
	);
};

export default Gallery;
