import { createContext, useContext, useState, useEffect, useMemo, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { onSnapshot } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { callChangeEmotionalWeight, callChangeVolatility, fetchAgents, getUserAgentDocRef } from './agentUtils';

const AgentContext = createContext();

export const AgentProvider = ({ children }) => {
	const [user] = useAuthState(auth);
	const uid = user ? user.uid : undefined;

	const [agent, setAgent] = useState(undefined);
	const [agents, setAgents] = useState([]);
	const [userAgent, setUserAgent] = useState(undefined);

	const timeoutRefs = useRef({});

	const { data: agentsData, isInitialLoading: agentsLoading } = useQuery(['agents'], fetchAgents, {
		refetchOnMount: false,
		enabled: true,
	});

	const agentId = agent ? agent.agentId : undefined;
	const userAgentDocRef = useMemo(() => getUserAgentDocRef(uid, agentId), [uid, agentId]);

	useEffect(() => {
		if (agentsData && !agentsLoading) {
			setAgents(agentsData);
		}
	}, [agentsData, agentsLoading]);

	useEffect(() => {
		if (userAgentDocRef) {
			const unsubscribe = onSnapshot(userAgentDocRef, (docSnapshot) => {
				if (docSnapshot.exists()) {
					setUserAgent(docSnapshot.data());
				}
			});

			return () => {
				unsubscribe();
			};
		}
	}, [userAgentDocRef]);

	const setAgentById = (agentId) => {
		if (agents && !agentsLoading) {
			setAgent(agents.find((obj) => obj.agentId === agentId));
		}
	};

	const updateEmotionalVolatility = async (newCurrent) => {
		if (timeoutRefs.current['volatility']) {
			clearTimeout(timeoutRefs.current['volatility']);
		}
		timeoutRefs.current['volatility'] = setTimeout(() => {
			callChangeVolatility(userAgent, newCurrent);
		}, 1000);
	};

	const updateEmotionalWeight = async (emotion, newWeight) => {
		if (timeoutRefs.current[emotion]) {
			clearTimeout(timeoutRefs.current[emotion]);
		}
		timeoutRefs.current[emotion] = setTimeout(() => {
			callChangeEmotionalWeight(userAgent, emotion, newWeight);
		}, 1000);
	};

	return (
		<AgentContext.Provider value={{ updateEmotionalVolatility, updateEmotionalWeight, setAgentById, agent, agents, userAgent, agentsLoading, userAgentLoading: false }}>
			{children}
		</AgentContext.Provider>
	);
};

export const useAgent = () => useContext(AgentContext);
