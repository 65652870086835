import React, { useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import IconPlay from '../icons/IconPlay';
import { useAgent } from '../../context/AgentContext';
import { sendPhotoMessage } from './sendPhotoMessage';
import { useUser } from '../../context/UserContext';

export default function SendPhotoModal({ closePortal, closeParentPortal }) {
	const { userAgent } = useAgent();
	const { user } = useUser();
	const [imgSrc, setImgSrc] = useState('');
	const [input, setInput] = useState('');

	const [canvas, setCanvas] = useState(undefined);

	async function onSelectFile(e) {
		const MAX_WIDTH = 1368;
		const MAX_HEIGHT = 1368;
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			const img = new Image();
			img.src = URL.createObjectURL(file);

			img.onload = async () => {
				const canvas = document.createElement('canvas');
				let width = img.width;
				let height = img.height;

				if (width > height) {
					if (width > MAX_WIDTH) {
						height *= MAX_WIDTH / width;
						width = MAX_WIDTH;
					}
				} else {
					if (height > MAX_HEIGHT) {
						width *= MAX_HEIGHT / height;
						height = MAX_HEIGHT;
					}
				}

				canvas.width = width;
				canvas.height = height;
				setCanvas(canvas);
				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0, width, height);
				const jpegDataURL = canvas.toDataURL('image/jpeg', 0.8);
				setImgSrc(jpegDataURL);
			};
		}
	}

	const sendMessage = async (e) => {
		e.preventDefault();

		sendPhotoMessage({ input, imgSrc, userAgent, canvas, user });
		setInput('');

		closeParentPortal();
		closePortal();
	};

	return (
		<>
			<form onSubmit={sendMessage}>
				<div className="flex justify-center items-center text-black">
					<div onClick={closePortal} className="fixed w-full h-full top-0 left-0 z-50 bg-opacity-50 bg-black"></div>
					<div style={{ maxWidth: '650px', minHeight: '256px' }} className="fixed top-2/4 -translate-y-2/4 p-2 sm:p-4 z-50 bg-slate-200 rounded-lg shadow w-90vw sm:w-60vw ">
						<div className="flex flex-wrap justify-center mx-auto">
							<div className="w-full text-center mb-2">
								<label className="cursor-pointer items-center text-white bg-slate-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-4 py-2 text-center">
									<input type="file" accept="image/jpeg, image/png, image/gif, image/webp" onChange={onSelectFile} style={{ display: 'none' }} />
									Choose File
								</label>
							</div>

							{imgSrc && <img src={imgSrc} alt="Preview" className="max-w-xs max-h-xs mx-2" />}
						</div>
						{imgSrc && (
							<div className="flex items-center mt-2">
								<input
									className="w-full h-12 outline-none border-none px-3 bg-white focus:ring-0"
									maxLength={128}
									value={input}
									onChange={(e) => setInput(e.target.value)}
									type="text"
									placeholder="Add a message with the Photo..."
								/>
								<button className="w-12 h-12 flex items-center justify-center p-2 bg-slate-700 text-white" type="submit">
									<IconPlay className="w-4 h-4" />
								</button>
							</div>
						)}
					</div>
				</div>
			</form>
		</>
	);
}
