export const badWords = [
	'acrotomophilia',
	'baby batter',
	'baby juice',
	'beastiality',
	'bestiality',
	'bukkake',
	'cialis',
	'date rape',
	'daterape',
	'fag',
	'faggot',
	'fecal',
	'futanari',
	'goatcx',
	'goatse',
	'golden shower',
	'goodpoop',
	'how to kill',
	'how to murder',
	'incest',
	'jail bait',
	'jailbait',
	'lolita',
	'underage',
	'negro',
	'neonazi',
	'nigga',
	'nigger',
	'paedophile',
	'pedobear',
	'pedophile',
	'raghead',
	'rape',
	'raping',
	'rapist',
	'sadism',
	'scat',
	'slut',
	'sodomize',
	'sodomy',
	'spastic',
	'suicide',
	'suicide girls',
	'towelhead',
	'tranny',
	'white power',
	'yellow showers',
	'zoophilia',
];
