import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage } from 'firebase/storage'; // Add this import

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Connect to the emulators
if (process.env.NODE_ENV === 'development') {
	connectFirestoreEmulator(db, '127.0.0.1', 8080); // Replace 'localhost' and 8080 with your emulator's host and port
	const functions = getFunctions(app);
	connectFunctionsEmulator(functions, '127.0.0.1', 5001); // Replace 'localhost' and 5001 with your emulator's host and port
	// connectStorageEmulator(storage, '127.0.0.1', 9199); // Replace 'localhost' and 9199 with your emulator's host and port
}
