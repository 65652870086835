import { findLatestAgentMessage, useMessages } from '../../hooks/useMessages';
import { useAgent } from '../../context/AgentContext';
import { useUser } from '../../context/UserContext';
import EmotionalVolatilitySlider from './options/EmotionalVolatilitySliders';
import RelationshipButtons from './options/RelationshipButtons';
import RoleplayModeButtons from './options/RoleplayModeButtons';
import EmotionalWeightsSliders from './options/EmotionalWeightsSliders';
import 'react-toastify/dist/ReactToastify.css';
import ContextLengthButtons from './options/ContextLengthButtons';
import IconCross from '../icons/IconCross';

const AgentOptionsModal = ({ closePortal }) => {
	const { userAgent } = useAgent();
	const { messages } = useMessages(userAgent);

	const { userData } = useUser();
	const { tokenCost } = findLatestAgentMessage(messages);

	return (
		<div className="flex justify-center items-center text-white">
			<div onClick={closePortal} className="fixed w-full h-full top-0 left-0 z-50 bg-opacity-50 bg-black"></div>
			{userAgent && userData && (
				<div style={{ maxWidth: '650px', maxHeight: '90vh' }} className="niceScrollbar fixed top-2/4 -translate-y-2/4 rounded w-11/12 bg-gray-700 p-2 sm:p-4  overflow-y-scroll z-50">
					<div className="flex items-center justify-between">
						<h1 className="text-xl text-purple-200">
							{userAgent.name}'s <span className="text-xs text-gray-400">(v{userAgent.version})</span>
							<span className="text-xs sm:text-sm italic text-white ml-1">personality settings</span>
						</h1>
						<button onClick={closePortal} className=" cursor-pointer text-gray-300 hover:text-white">
							<IconCross />
						</button>
					</div>

					<div className="mb-8 text-xs text-gray-300">
						<p>Synth Love Score: {userAgent.xp}</p>
						<p>Attraction Points to spend: {userAgent.availablePoints}</p>
						<p>Last interaction credits cost: {Math.round(tokenCost / 10)}</p>
					</div>
					<ContextLengthButtons />
					<RoleplayModeButtons />
					<RelationshipButtons />
					<EmotionalVolatilitySlider userAgent={userAgent} />

					<p>Emotional Weights:</p>

					<EmotionalWeightsSliders emotions={userAgent.emotionalStates} />
				</div>
			)}
		</div>
	);
};

export default AgentOptionsModal;
