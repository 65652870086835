import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, db } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { systemMessageTemplate } from '../../constants/constants';

export const sendPhotoMessage = async (data) => {
	const { input, imgSrc, userAgent, canvas, user } = data;

	console.log(userAgent);
	try {
		const doc = { text: `One moment, your image is being processed {{${systemMessageTemplate.sendPhotoMessage}}}`, system: true, timestamp: serverTimestamp() };
		const docRef = await addDoc(collection(db, `users/${user.uid}/agents/${userAgent.agentId}/messages`), doc);

		const functions = getFunctions(app);
		const userPhotoSubmitFunction = httpsCallable(functions, 'userPhotoSubmit');
		const response = await userPhotoSubmitFunction({
			text: input,
			image: imgSrc,
			agentId: userAgent.agentId,
			width: canvas.width,
			height: canvas.height,
			systemMessageId: docRef.id, // Send the docRef path
		});

		console.log('Cloud Function response:', response.data);
	} catch (error) {
		console.error('Error calling Firebase Cloud Function:', error);
	}
};
